<script>
  import { Html, LayerCake, Svg } from 'layercake'
  import { onMount, getContext} from 'svelte'
  import ContainerPointerHandler from '../ContainerPointerHandler.svelte'
  import MapBackground from './MapBackground.svelte'
  import StorageUnitRow from './StorageUnitRow.svelte'
  import MapBorder from './MapBorder.svelte'

  export let storageUnitTypesById
  export let storageUnitRows
  export let selectedStorageUnitRowId

  export let mapWidth
  export let mapHeight
  export let mapBackgroundImageUrl

  export let viewXCenter
  export let viewYCenter
  export let viewWidth

  let screenWidth = 500
  let screenHeight = 500
  $: viewHeight = viewWidth / (screenWidth / screenHeight)
  $: viewHalfWidth = viewWidth / 2
  $: viewHalfHeight = viewHeight / 2

  export let spacePressed

  let container
  function getScreenDimensions() {
    const rect = container.getBoundingClientRect()
    screenWidth = rect.width
    screenHeight = rect.height
  }

  onMount(() => {
    getScreenDimensions()
  })

  let updateDebugStore = getContext("updateDebugStore")
  $: updateDebugStore({MapViewWidth: viewWidth, MapViewHeight: viewHeight})
</script>

<svelte:window on:resize={getScreenDimensions} />

<div
  bind:this={container}
  class:cursor-grab={spacePressed}
  class="w-full overflow-clip h-full"
>
    <LayerCake
      data={storageUnitRows}

      xDomain={[viewXCenter - viewHalfWidth, viewXCenter + viewHalfWidth]}
      yDomain={[viewYCenter + viewHalfHeight, viewYCenter - viewHalfHeight]}

    >
      <ContainerPointerHandler
        on:domainpointerdown
        on:domainpointerup
        on:domainpointermove
      >
        <Html>
          <MapBackground
            {mapWidth}
            {mapHeight}
            {mapBackgroundImageUrl}
          />
        </Html>

        <Svg>
          <MapBorder
            {mapBackgroundImageUrl}
            {mapWidth}
            {mapHeight}
          />
          {#each storageUnitRows as storageUnitRow}
            <StorageUnitRow
              {storageUnitRow}
              selected={storageUnitRow.id === selectedStorageUnitRowId}
              {storageUnitTypesById}
              on:domainpointerdown
              on:domainpointerup
              on:domainpointermove
            />
          {/each}
        </Svg>
      </ContainerPointerHandler>
    </LayerCake>
</div>
