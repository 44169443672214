import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea"]

  // insert text at cursor position
  insert(e) {
    e.preventDefault()
    const text = e.currentTarget.dataset.value.trim()
    const textarea = this.textareaTarget
    textarea.focus()
    const start = textarea.value.slice(0, textarea.selectionStart)
    var end = textarea.value.slice(textarea.selectionEnd)
    textarea.value = start + text + end
  }
}
