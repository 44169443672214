import { Controller } from "@hotwired/stimulus"

let scrollTop = 0;
export default class extends Controller {
  static targets = ["container", "shadow"]

  open(e) {
    if (!this.shadowTarget.classList.contains("hidden")) {
      this.close(e)
      return
    }

    e.preventDefault();
    document.body.classList.add("overflow-hidden")
    this.shadowTarget.classList.add("opacity-0")
    this.containerTarget.classList.add("-ml-72")

    this.shadowTarget.classList.remove("hidden")
    this.containerTarget.classList.remove("hidden")

    setTimeout(() => {
      this.containerTarget.classList.add("transition-all", "ease-in-out", "duration-300", "delay-[20ms]")
      this.shadowTarget.classList.add("transition-all", "ease-in-out", "duration-300", "delay-[20ms]")

      this.shadowTarget.classList.remove("opacity-0")
      this.containerTarget.classList.remove("-ml-[500px]")

      this.containerTarget.classList.add("ml-0")
      this.shadowTarget.classList.add("opacity-50")
    }, 5);
  }

  close(e) {
    e.preventDefault();
    document.body.classList.remove("overflow-hidden")
    this.shadowTarget.classList.remove("opacity-50")
    this.shadowTarget.classList.add("opacity-0")

    this.containerTarget.classList.remove("ml-0")
    this.containerTarget.classList.add("-ml-[500px]")

    setTimeout(() => {
      this.shadowTarget.classList.add("hidden")
      this.containerTarget.classList.add("hidden")
    }, 500)
  }

  #setScrollTop = () => {
    scrollTop = document.querySelector(`[data-company-admin-dashboard-target="container"]`).scrollTop || 0
  }

  #restoreScrollTop = () => {
    if (scrollTop > 0) {
      document.querySelector(`[data-company-admin-dashboard-target="container"]`).scrollTop = scrollTop
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-visit", this.#setScrollTop)
    document.removeEventListener("turbo:render", this.#restoreScrollTop)
  }

  connect() {
    document.addEventListener("turbo:before-visit", this.#setScrollTop)
    document.addEventListener("turbo:render", this.#restoreScrollTop)
  }
}
