<script>
  import LeaseEditor from "./LeaseEditor.svelte"
  import BackgroundEditor from "./BackgroundEditor.svelte"
  import Moveable from "svelte-moveable"
  import { onDestroy, onMount, setContext } from "svelte"
  import { debounce } from "utils"
  import CustomerPopover from "./CustomerPopover.svelte"
  import { createZoom } from "./zoom.js"
  import ZoomControls from "./ZoomControls.svelte"
  import { post } from "api"

  export let unit
  export let activeLeases
  export let updateLeasePositionUrl

  let leases = activeLeases
  let bg = unit
  let targetRef = null
  let containerRef = null
  let mode = "view"

  // Zoom controls
  const zoom = createZoom(unit.id)
  let scale = 1
  let transform = ""
  let zoomUnsub = zoom.subscribe((s) => {
    scale = s.scale
    transform = s.transform
  })
  $: targetStyle = transform ? `transform: ${transform}` : ""
  onDestroy(zoomUnsub)
  function reset() {
    targetRef.classList.add("transition-transform")
    let newTransform = `translate(100px, 150px)`
    zoom.reset(0.6, newTransform)
    setTimeout(() => {
      targetRef.classList.remove("transition-transform")
    }, 500)
  }
  // End Zoom controls

  // Edit Lease
  let selectedSerialNum = ""
  let selectedLeaseRect = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: 0,
    width: 0,
  }
  function setSelectedLease(leaseId, rect) {
    selectedSerialNum = leaseId
    if (rect) {
      selectedLeaseRect = rect
    }
  }
  setContext("setSelectedLease", setSelectedLease)
  const updateLeasePosition = debounce((lease_id, params) => {
    post(updateLeasePositionUrl, { lease_id, ...params })
  }, 500)
  // End Edit Lease

  // Change Background
  function buttonClasses() {
    const template = document.querySelector("template#button").content
    return document
      .importNode(template, true)
      .querySelector("a")
      .getAttribute("class")
  }
  let btn = buttonClasses()
  function changeMode(e) {
    mode = e.target.value
  }
  let bgInput = document.querySelector(
    "form#bulk_storage_site_map_upsert input[type=file]"
  )
  let bgUrl = unit.background_url || ""
  function previewFile(e) {
    bgUrl = URL.createObjectURL(e.target.files[0])
    bgInput.closest("form").requestSubmit()

    setTimeout(() => {
      bgInput.value = ""
    }, 500)
  }
  bgInput.addEventListener("change", previewFile)
  // End Change Background
  let selectedCustomerId = ""
  const uniqueCustomer = new Map()
  leases.forEach((lease) => {
    const key = lease.customer.id.id
    uniqueCustomer.set(key, lease.customer)
  })
  let customers = [...uniqueCustomer.values()].sort((a, b) => {
    return a.name.localeCompare(b.name, "en")
  })

  onMount(() => {
    containerRef.style.height = `${
      window.innerHeight - containerRef.offsetTop - 20
    }px`
  })

  function handleWheel(event) {
    event.preventDefault()
    const multiplier = event.ctrlKey ? -0.01 : -0.004
    zoom.in(event.deltaY * multiplier)
  }
</script>

<div
  class="flex flex-col sm:flex-row gap-4 items-center justify-between mb-4 font-display"
>
  <div class="flex items-center flex-col sm:flex-row w-full sm:w-auto gap-4">
    <div class="flex items-center w-full font-display font-medium">
      <div class="w-full">
        <input
          id="view"
          checked={mode === "view"}
          type="radio"
          name="mode"
          on:change={changeMode}
          value="view"
          class="peer hidden"
        />
        <label
          for="view"
          class="border-gray-300 w-full flex border rounded-l-md text-center text-sm bg-white px-4 cursor-pointer p-2 peer-checked:bg-black peer-checked:text-white hover:bg-slate-100"
        >
          <svg class="w-5 h-5 fill-black inline-block">
            <svg
              id="cursor-arrow-ripple"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M17.303 5.197A7.5 7.5 0 006.697 15.803a.75.75 0 01-1.061 1.061A9 9 0 1121 10.5a.75.75 0 01-1.5 0c0-1.92-.732-3.839-2.197-5.303zm-2.121 2.121a4.5 4.5 0 00-6.364 6.364.75.75 0 11-1.06 1.06A6 6 0 1118 10.5a.75.75 0 01-1.5 0c0-1.153-.44-2.303-1.318-3.182zm-3.634 1.314a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68z"
                clip-rule="evenodd"
              />
            </svg>
          </svg>
          View
        </label>
      </div>
      <div class="w-full">
        <input
          id="edit"
          class="hidden peer"
          checked={mode === "edit"}
          type="radio"
          name="mode"
          on:change={changeMode}
          value="edit"
        />
        <label
          for="edit"
          class="border-gray-300 w-full flex bg-white text-center text-sm border-l-transparent border rounded-r-md p-2 px-4 cursor-pointer peer-checked:bg-black peer-checked:text-white hover:bg-slate-100"
        >
          <svg class="w-5 h-5 fill-black inline-block">
            <svg
              id="adjustments-horizontal"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
              />
            </svg>
          </svg>
          Edit
        </label>
      </div>
    </div>

    <div class="w-full min-w-44">
      <select
        class="w-full rounded-md font-display px-3 py-2 placeholder-gray-400 sm:text-sm border-gray-300 bg-white text-gray-900 focus:border-black focus:outline-none focus:ring-black"
        bind:value={selectedCustomerId}
      >
        <option value="">All Customers</option>
        {#each customers as customer}
          <option value={customer.id.id}>{customer.name} </option>
        {/each}
      </select>
    </div>
  </div>

  <button
    class={btn}
    on:click={() => {
      bgInput.click()
    }}
  >
    <svg class="w-5 h-5 fill-black inline-block">
      <svg
        id="photo-o"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
        />
      </svg>
    </svg>
    Change Background
  </button>
</div>

<div
  role="cell"
  tabindex="-1"
  bind:this={containerRef}
  class="flex w-full rounded font-display relative select-none {mode === 'view'
    ? 'cursor-grab'
    : ''}"
  on:mousedown={() => {
    if (mode === "edit") return
    containerRef.classList.remove("cursor-grab")
    containerRef.classList.add("cursor-grabbing")
  }}
  on:mouseup={() => {
    if (mode === "edit") return
    containerRef.classList.add("cursor-grab")
    containerRef.classList.remove("cursor-grabbing")
  }}
  on:focus={() => {}}
  on:wheel={handleWheel}
  on:mouseover={() => {
    setSelectedLease("")
  }}
>
  <div
    class="bg-white border-2 rounded-xl w-full z-0 overflow-hidden left-0 right-0 top-0 bottom-0 absolute"
  >
    <div style={targetStyle} bind:this={targetRef}>
      <div
        style="transform: scale({scale});"
        class="container transition-all ease-linear"
      >
        <BackgroundEditor
          cullText={scale < 0.45}
          url={bgUrl}
          edit={mode === "edit"}
          unit={bg}
        />
        {#each leases as lease}
          <LeaseEditor
            cullText={scale < 0.45}
            update={updateLeasePosition}
            edit={mode === "edit"}
            hide={Boolean(selectedCustomerId) &&
              lease.customer.id.id !== selectedCustomerId}
            {lease}
          />
        {/each}
      </div>
    </div>

    <ZoomControls zoomReset={reset} zoomIn={zoom.in} zoomOut={zoom.out} />
  </div>
</div>

{#each leases as lease}
  {#if lease.serial_num == selectedSerialNum}
    <CustomerPopover {lease} {selectedSerialNum} {selectedLeaseRect} />
  {/if}
{/each}

{#if mode === "view"}
  <Moveable
    target={containerRef}
    draggable={true}
    origin={false}
    hideDefaultLines={true}
    hideChildMoveableDefaultLines={true}
    throttleDrag={1}
    edgeDraggable={false}
    startDragRotate={0}
    throttleDragRotate={0}
    onDrag={(e) => {
      const pos = (targetRef.style.transform || ",")
        .replace("translate(", "")
        .replace(")", "")
        .replace(/px/g, "")
        .split(",")
        .map((p) => Number(p))

      const newTransform = `translate(${pos[0] + e.delta[0]}px, ${
        pos[1] + e.delta[1]
      }px)`
      targetRef.style.transform = newTransform
      zoom.updateTransform(newTransform)
    }}
  />
{/if}

<button
  class={btn + " mt-4"}
  on:click={() => {
    window.history.back()
  }}
>
  <svg class="w-5 h-5 fill-black inline-block">
    <svg
      id="arrow-left-o"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
      />
    </svg>
  </svg>
  Back
</button>
