import { Controller } from "@hotwired/stimulus"
import {
  create,
  get,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON
} from "@github/webauthn-json/browser-ponyfill";

export default class extends Controller {
  static targets = ["publicKeyCredential"]
  static values = { options: Object };

  #callback = (event, fn, parser) => {
    if (this.publicKeyCredentialTarget.value.length > 0 || !window.navigator.credentials) { return }

    event.preventDefault()
    const options = parser({ publicKey: this.optionsValue })
    fn(options).then(pk => {
      this.publicKeyCredentialTarget.value = JSON.stringify(pk)
      this.element.requestSubmit();
    }).catch(e => { alert(e.message) })
  }

  create = (e) => {
    this.#callback(e, create, parseCreationOptionsFromJSON)
  }

  verify = (e) => {
    this.#callback(e, get, parseRequestOptionsFromJSON)
  }
}
