import { Controller } from "@hotwired/stimulus";

function showUploadWindow(title) {
	if (document.querySelectorAll("#upload-progress-modal").length > 0) {
		return;
	}

	const template = document.querySelector(
		"template#upload-progress-template"
	);
	if (template) {
		const content = template.content;
		const alt = document.importNode(content, true);
		const button = alt?.querySelector("button");
		if (button) {
			button.addEventListener("click", () => {
				hideUploadWindow();
			});
		}


		const openDialog = document.querySelector("dialog[open]")
		if (openDialog) {
			openDialog.appendChild(alt);
		} else {
			document.body.appendChild(alt);
		}
		updateUploadTitle(title);
		document.body.classList.add("overflow-hidden");
	}
}

function hideUploadWindow() {
	document.body.classList.remove("overflow-hidden");
	const modal = document.querySelector("#upload-progress-modal");
	if (modal) {
		modal.parentElement?.removeChild(modal);
	}
}

function updateUploadTitle(title) {
	const alertTitle = document.querySelector("#alert-title");
	if (alertTitle && title) {
		alertTitle.innerText = title;
	}
}

function updateUploadProgress(progress = 0) {
	const progressMeter = document.querySelector("#progress-meter");
	progressMeter?.setAttribute("style", `width: ${progress}%`);

	const progressText = document.querySelector("#progress-text");
	if (progressText) {
		progressText.innerText = `${progress.toFixed()}% Complete`;
	}
}

// window.test = () => {
// 	showUploadWindow("Uploading...");
// 	let progress = 0;
// 	let i = setInterval(() => {
// 		progress = progress + 1
// 		updateUploadProgress(progress);
// 		if (progress >= 100) {
// 			clearInterval(i);
// 			setTimeout(() => {
// 				updateUploadTitle("Upload complete");
// 				setTimeout(hideUploadWindow, 1000);
// 			}, 1000);
// 		}
// 	}, 10)
// }

export default class extends Controller {
	connect() {
		document.addEventListener("direct-uploads:start", function (e) {
			showUploadWindow("Uploading...");
		});
		document.addEventListener("direct-upload:progress", function (e) {
			const progress = e.detail.progress;
			updateUploadProgress(progress);
		});
		document.addEventListener("direct-uploads:end", function (e) {
			updateUploadTitle("Upload complete");

			setTimeout(hideUploadWindow, 100);
		});
		document.addEventListener("direct-upload:error", function (e) {
			updateUploadTitle("Error uploading file");
		});
		document.addEventListener("direct-upload:title", function (e) {
			// @ts-ignore
			updateUploadTitle(e.detail.title);
		});
	}
}
