<script>
  import { createEventDispatcher } from "svelte"

  const dispatch = createEventDispatcher()

  export let canvasWidth
  export let canvasHeight

  document
    .querySelector("[name='storage_unit_type[site_map_shape][height]'")
    .addEventListener("input", (e) => {
      canvasHeight = Number(e.target.value)
      dispatch("changedimensions", { canvasWidth, canvasHeight })
    })

  document
    .querySelector("[name='storage_unit_type[site_map_shape][width]'")
    .addEventListener("input", (e) => {
      canvasWidth = Number(e.target.value)
      dispatch("changedimensions", { canvasWidth, canvasHeight })
    })
</script>
