import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"]

  showAll(e) {
    e.currentTarget.classList.add("hidden")
    this.toggleTargets.forEach(t => {
      t.classList.remove("hidden")
    })
  }
}
