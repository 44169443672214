import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchForm", "searchInput", "searchResults"]

  searchFormChange(e) {
    let formData = new FormData(this.searchFormTarget)

    const searchPath = this.data.get("search-path")
    fetch(searchPath, {
      method: "POST",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        },
      credentials: 'same-origin',
      body: formData
    }).then((response) => {
      if(response.ok) {
        return response.text()
      }
    }).then((responseText) => {
      this.searchResultsTarget.outerHTML = responseText
    })
  }
}
