<script lang="ts">
  import type { UnitType } from "api/site_maps"
  import { getContext, onDestroy } from "svelte"
  import { numberToCurrency } from "utils"

  export let selectedUnitRect: DOMRect
  export let selectedUnit: string
  export let unit: { id: string; name: string; dimensions: string }

  let units: { [key: string]: UnitType } = {}
  let unsubscribe = () => {}
  const statuses = getContext("sitemapStatuses")
  if (statuses) {
    // @ts-ignore - Svelte types are not written for stores
    unsubscribe = statuses.subscribe((value) => {
      units = value
    })
  }
  onDestroy(unsubscribe)

  const storageUnit: UnitType = units[unit.id]

  const styleButtonXpath = "//span[contains(text(),'Edit Location')]"
  const buttonClasses = [
    ...(document.evaluate(
      styleButtonXpath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue?.parentElement?.classList || []),
  ].join(" ")

  $: top =
    window.innerHeight - 150 - selectedUnitRect.top > 200
      ? selectedUnitRect.bottom - 10
      : selectedUnitRect.top - 140
  $: side =
    window.innerWidth - selectedUnitRect.left < 290
      ? `left: ${selectedUnitRect.left - 290}px;`
      : `left: ${selectedUnitRect.left + selectedUnitRect.width + 10}px;`
  $: open = unit.id === selectedUnit
</script>

<div
  id="customer-popover-{unit.id}"
  style={`top: ${top}px; min-height: 150px; width: 250px; ${side}`}
  class={`${open ? "fixed" : "hidden"} bg-white p-4 rounded-xl shadow-lg`}
>
  <div class="flex h-full w-full">
    <div class="flex flex-col w-full">
      <div class="flex flex-row justify-between items-center">
        <a
          data-turbo-frame="_top"
          href="/company_admin/storage_units/{unit.id}"
        >
          <p
            class="font-bold flex items-center text-lg text-slate-700 hover:text-slate-500 hover:underline"
          >
            Unit #{unit.name}
          </p>
        </a>
        <p class="font-semibold text-sm">
          ${[`${storageUnit.price}`, storageUnit.priceDuration]
            .filter((s) => s)
            .join(" / ")}
        </p>
      </div>
      <div class="text-xs text-slate-500 mb-2 break-before-all">
        {unit.dimensions}
      </div>
      <hr />

      {#if storageUnit.disabled}
        <div class="flex flex-col flex-grow items-center justify-center">
          <div
            class="text-gray-700 font-display font-semibold rounded-lg text-center px-6 pt-2 my-6 block"
          >
            Disabled<br />
            <span class="font-normal">This unit cannot be leased</span>
          </div>
        </div>
      {:else if storageUnit.auction}
        {#if storageUnit.customer}
          <p>
            <a
              data-turbo-frame="_top"
              class="block mt-2 underline text-slate-700 hover:text-slate-500"
              href="/company_admin/customers/{storageUnit.customer?.id}"
            >
              {storageUnit.customer?.name ?? "???"}
            </a>
            {#if storageUnit.customer}
              <p class="font-bold">Balance:</p>
              <div
                class="flex flex-grow fbg-red-400 items-center justify-center"
              >
                <p class="text-center py-4 text-2xl font-bold">
                  {numberToCurrency(storageUnit.customer.balance)}
                </p>
              </div>
            {/if}
          </p>
        {/if}
        <div class="flex flex-col flex-grow items-center justify-center">
          <div
            class="text-yellow-800 bg-yellow-100 font-display font-semibold rounded-full border text-center px-6 py-2 my-6 block"
          >
            In Auction
          </div>
        </div>
      {:else if storageUnit.occupancyStatus === "vacant"}
        <div class="flex py-8 flex-grow items-center justify-center">
          <a
            data-turbo-frame="_top"
            href="/company_admin/move_in_wizard/choose_customer?storage_unit_id={unit.id}"
            class={buttonClasses}
          >
            Move In
          </a>
        </div>
      {:else}
        <p>
          <a
            data-turbo-frame="_top"
            class="block mt-2 underline text-slate-700 hover:text-blue-500"
            href="/company_admin/customers/{storageUnit.customer?.id}"
          >
            {storageUnit.customer?.name ?? "???"}
          </a>
          {#if storageUnit.customer}
            <p class="font-bold">Balance:</p>
            <div class="flex flex-grow fbg-red-400 items-center justify-center">
              <p class="text-center py-4 text-2xl font-bold">
                {numberToCurrency(storageUnit.customer.balance)}
              </p>
            </div>
          {/if}
        </p>
        {#if storageUnit.occupancyStatus === "past_due"}
          <div class="flex items-center justify-center gap-4">
            <span class="text-red-500">
              {numberToCurrency(storageUnit.customer?.pastDue ?? 0)}
            </span>
            <div
              class="bg-red-500 text-white text-sm inline rounded-full py-1 px-2"
            >
              Past Due
            </div>
          </div>
        {/if}
      {/if}
      {#if storageUnit.locked}
        <div class="flex flex-col flex-grow items-center justify-center">
          <div
            class="text-cyan-800 bg-cyan-100 border-cyan-200 font-display font-semibold rounded-full border text-center px-6 py-2 my-6 block"
          >
            Locked
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>
