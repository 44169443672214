import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paymentMethodAdded", "addButton"]

  requestSubmit = (e) => {
    const ach = document.querySelector("input#ach")
    if (ach && ach.checked) {
      const submitButton = ach.parentElement.querySelector(`[data-tilled-target="submit"]`)
      submitButton.click();
    } else {
      const submitButton = this.element.querySelector(`[data-tilled-target="submit"]`)
      submitButton.click();
    }

    const button = e.currentTarget
    const text = button.innerText
    button.disabled = true
    button.innerText = "Loading..."
    setTimeout(() => {
      button.disabled = false
      button.innerText = text
    }, 2000)
  };


  close = () => {
    const dialog = this.element.querySelector("dialog")
    dialog?.close();
  };

  open = () => {
    const dialog = this.element.querySelector("dialog")
    document.body.classList.add("overflow-hidden");
    dialog.showModal();
    setTimeout(() => {
      dialog.classList.remove("backdrop:opacity-0", "opacity-0")
      dialog.classList.add("backdrop:opacity-100", "opacity-100")
    }, 5)
  }

  connect() {
    const dialog = this.element.querySelector("dialog")
    const submitButtons = this.element.querySelectorAll(`[data-tilled-target="submit"]`)
    submitButtons.forEach((button) => {
      button.classList.add("hidden")
    })

    dialog.addEventListener("close", () => {
      document.body.classList.remove("overflow-hidden");
      dialog.classList.add("backdrop:opacity-0", "opacity-0")
      dialog.classList.remove("backdrop:opacity-100", "opacity-100")
    });

    document.addEventListener("tokenized-payment-method:created", () => {
      this.dispatch("success", { prefix: "tokenized-status" })
    })
  }
}
