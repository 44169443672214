import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    timeout: Number,
  };

  toggle = () => {
    const children = Array.from(this.element.children)
    children.forEach((child) => {
      child.classList.toggle("hidden")
    })
  }

  showLoading = () => {
    this.toggle()
    setTimeout(this.toggle, this.timeoutValue)
  }
}
