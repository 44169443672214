import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "label", "description"]

  formatTemplateName = (e) => {
    const value = e.target.value
    const formattedValue = value.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase()
    e.target.value = formattedValue
  }

  update = () => {
    const label = this.labelTarget.value.trim().length > 0 ? this.labelTarget.value : "Name"
    const caption = this.descriptionTarget.value.trim().length > 0 ? this.descriptionTarget.value : "Description"
    this.previewTarget.parentElement.querySelector("label").innerText = label
    this.previewTarget.parentElement.querySelector("div").innerText = caption
  }

  connect() {
    this.update()
  }
}