import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["gatewaySelect", "gatewayNotes", "gatewayExampleArguments"]

  gatewayChange() {
    const gateways = JSON.parse(this.gatewaySelectTarget.dataset.gateways)
    const selectedGateway = gateways[this.gatewaySelectTarget.value] || { notes: "", example_args: "" }
    this.gatewayNotesTarget.value = selectedGateway.notes
    this.gatewayExampleArgumentsTarget.value = selectedGateway.example_args
  }
}
