<script>
  import { createEventDispatcher, onMount } from "svelte"
  import { LayerCake, Svg } from "layercake"

  const dispatch = createEventDispatcher()

  import ContainerPointerHandler from "../ContainerPointerHandler.svelte"
  import Grid from "./Grid.svelte"
  import Unit from "./Unit.svelte"
  import UnitName from "./UnitName.svelte"

  export let containerClass

  export let screenWidth
  export let screenHeight

  export let canvasWidth
  export let canvasHeight

  export let mode
  export let polygon
  export let namePosition
  export let nameRotation

  let drawingInProgressPolygon
  function modeChanged(newMode) {
    if (newMode === "editShape") {
      drawingInProgressPolygon = []
    }
  }
  $: modeChanged(mode)

  export let snapToGrid

  function distance(x1, y1, x2, y2) {
    const xd = x2 - x1
    const yd = y2 - y1
    return Math.sqrt(xd * xd + yd * yd)
  }

  function handleClick(e) {
    if (mode === "editShape") {
      let x = e.detail.x
      let y = e.detail.y

      if (snapToGrid) {
        x = Math.round(x)
        y = Math.round(y)
      }

      if (x < 0) {
        x = 0
      } else if (x > canvasWidth) {
        x = canvasWidth
      }
      if (y < 0) {
        y = 0
      } else if (y > canvasHeight) {
        y = canvasHeight
      }

      if (
        drawingInProgressPolygon.length > 0 &&
        distance(
          x,
          y,
          drawingInProgressPolygon[0][0],
          drawingInProgressPolygon[0][1]
        ) < 0.5
      ) {
        dispatch("polygonchanged", { polygon: drawingInProgressPolygon })
      } else {
        drawingInProgressPolygon.push([x, y])
        drawingInProgressPolygon = drawingInProgressPolygon
      }
    } else if (mode === "editName") {
      dispatch("namepositionchanged", {
        namePosition: { x: e.detail.x, y: e.detail.y },
      })
    }
  }

  let moving = false
  function onDown(e) {
    moving = true
  }

  function onUp(e) {
    if (moving) {
      moving = false
    }
  }
  function onMove(e) {
    if (moving) {
      dispatch("namepositionchanged", {
        namePosition: { x: e.detail.x, y: e.detail.y },
      })
    }
  }
</script>

<svelte:window on:mouseup={onUp} />

<div class={containerClass}>
  <div
    style:width={`${screenWidth + 16 + 16}px`}
    style:height={`${screenHeight + 16 + 16}px`}
    class="bg-gray-100 overflow-hidden"
  >
    <LayerCake
      data={mode === "editShape" ? drawingInProgressPolygon : polygon}
      custom={{ drawing: mode === "editShape" }}
      x={(p) => p[0]}
      y={(p) => p[1]}
      xDomain={[0, canvasWidth]}
      yDomain={[canvasHeight, 0]}
      xPadding={[16, 16]}
      yPadding={[16, 16]}
    >
      <ContainerPointerHandler on:domainpointermove={onMove}>
        <Svg>
          <Grid />

          <Unit />

          <UnitName pointerDown={onDown} {namePosition} {nameRotation} />
        </Svg>
      </ContainerPointerHandler>
    </LayerCake>
  </div>
</div>
