<script>
  import { fly } from "svelte/transition"
  import { numberToCurrency } from "utils"

  export let selectedLeaseRect
  export let selectedSerialNum
  export let lease

  $: top =
    window.innerHeight - 150 - selectedLeaseRect.top > 200
      ? selectedLeaseRect.bottom - 10
      : selectedLeaseRect.top - 140
  $: side =
    window.innerWidth - selectedLeaseRect.left < 290
      ? "right: 50px;"
      : `left: ${selectedLeaseRect.left + 10}px;`
  $: open = lease.serial_num === selectedSerialNum
</script>

<div
  transition:fly={{ duration: 600, y: 20 }}
  style={`top: ${top}px; min-height: 150px; width: 250px; ${side}`}
  class={`${
    open ? "fixed" : "hidden"
  } bg-white p-4 rounded-xl shadow-lg font-display`}
>
  <div class="flex h-full w-full">
    <div class="flex flex-col w-full">
      <div class="flex flex-row justify-between items-center">
        <a data-turbo-frame="_top" href={lease.url}>
          <p
            class="font-bold flex items-center text-lg text-blue-700 hover:text-blue-500 hover:underline"
          >
            Lease #{lease.serial_num}
          </p>
        </a>
        <p class="font-semibold text-sm">
          ${[`${lease.price.toFixed()}`, lease.price_footage].join(" / ")} ft.
        </p>
      </div>
      <hr />

      <p>
        <a
          data-turbo-frame="_top"
          class="block mt-2 underline text-blue-700 hover:text-blue-500"
          href={lease.customer?.url}
        >
          {lease.customer?.name}
        </a>
        {#if lease.customer}
          <p class="font-bold">Balance:</p>
          <div class="flex flex-grow fbg-red-400 items-center justify-center">
            <p class="text-center py-4 text-2xl font-bold">
              {numberToCurrency(lease.customer.balance)}
            </p>
          </div>
        {/if}
      </p>
      {#if lease.occupancyStatus === "past_due"}
        <div class="flex items-center justify-center gap-4">
          <span class="text-red-500">
            {numberToCurrency(lease.customer?.pastDue ?? 0)}
          </span>
          <div
            class="bg-red-500 text-white text-sm inline rounded-full py-1 px-2"
          >
            Past Due
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>
