import { Controller } from "@hotwired/stimulus"
import { loadScript } from "./tilled_controller";

async function initChart() {
  return new Promise((resolve) => {
    if (window.ApexCharts) {
      resolve();
    } else {
      loadScript("https://cdn.jsdelivr.net/npm/apexcharts");
      let i = setInterval(() => {
        if (window.ApexCharts) {
          clearInterval(i);
          resolve();
        }
      }, 100);
    }
  });
}

function numberToCurrency(value) {
  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  }).split(".")[0];
}

export default class extends Controller {
  static values = {
    title: String,
    height: Number,
    type: { default: "area", type: String }, // area, pie
    theme: { default: "palette1", type: String }, // up to palette10
    showToolbar: { default: true, type: Boolean },
    tooltipTheme: { default: "light", type: String },// light, dark
    series: Array, // {name, data}
  };

  pieChart = ({ ctx, title, height, series }) => {
    let chartSeries = []
    let labels = []
    Object.entries(series[0]).forEach(([label, value]) => {
      labels.push(label)
      chartSeries.push(value)
    });

    let chart = new ApexCharts(ctx, {
      theme: { palette: this.themeValue },
      title: {
        text: title,
        align: 'left'
      },
      colors: ['#22c55e', '#dc2626'],
      chart: {
        type: 'donut',
      },
      series: chartSeries,
      labels,
      legend: false,
      chart: {
        type: 'donut',
        height,
        legend: false,
      },
      dataLabels: {
        enabled: false
      },
    })

    return chart
  }

  areaChart = ({ ctx, title, height, series }) => {
    let chart = new ApexCharts(ctx, {
      title: {
        text: title,
        align: 'left',
      },
      theme: { palette: this.themeValue },
      chart: {
        type: 'area',
        stacked: false,
        height: height,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: "pan",
          show: this.showToolbarValue
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      tooltip: {
        theme: this.tooltipThemeValue,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      stroke: {
        curve: 'smooth',
      },
      series: series,
      grid: {
        borderColor: "#444",
        clipMarkers: false,
        xaxis: {
          lines: {
            show: true

          },
        },
        yaxis: {
          lines: {
            show: true
          },
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          formatter: function (val, loa) {
            return numberToCurrency(val)
          },
        },
        format: function (val) {
          return numberToCurrency(val)
        },
        title: {
          text: 'Amount'
        },
        opposite: true,
      }
    })

    return chart
  }

  connect = () => {
    const type = this.typeValue
    const ctx = this.element
    const title = this.titleValue
    const height = this.heightValue
    const series = this.seriesValue


    initChart().then(() => {
      const options = { ctx, title, height, series }
      const chart = type == "area" ? this.areaChart(options) : this.pieChart(options)
      chart.render();
    })
  }
}
