import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "preview", "targetPrice", "startDate", "billingInterval"]

  calculateCredit() {
    if (this.checkboxTarget.checked && Number(this.targetPriceTarget.value) > 0) {
      const frame = this.previewTarget.querySelector("turbo-frame") || document.createElement("turbo-frame")
      frame.setAttribute("id", "proration-preview")
      frame.setAttribute("src", `proration_preview${window.location.search}&date=${this.startDateTarget.value}&billing_interval_id=${this.billingIntervalTarget.value}&target_price=${this.targetPriceTarget.value}`)
      this.previewTarget.appendChild(frame)
    }
  }

  showCredit(e) {
    if (e.currentTarget.checked) {
      this.calculateCredit()
    } else {
      this.previewTarget.innerHTML = ''
      document.querySelector("#create_lease_next_bill_date").disabled = false
    }
  }
}
