import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["paymentMethodPresent", "paymentMethodForm"]

  success() {
    this.element.querySelector("dialog")?.close()
    this.paymentMethodPresentTarget.classList.remove("hidden")
    this.paymentMethodFormTarget.classList.add("hidden")
  }
}