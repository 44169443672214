<script>
  import { createEventDispatcher } from "svelte"

  const dispatch = createEventDispatcher()

  export let x
  export let y
  export let rotation

  let formEl

  function handlePropose() {
    const elements = formEl.elements
    const formX = Number(elements["unitNamePositionX"].value)
    const formY = Number(elements["unitNamePositionY"].value)

    const formRotation = Number(
      document.querySelector(
        "[name='storage_unit_type[site_map_name_rotation]'"
      ).value
    )

    document.querySelector(
      "[name='storage_unit_type[site_map_name_position][x]']"
    ).value = formX
    document.querySelector(
      "[name='storage_unit_type[site_map_name_position][y]']"
    ).value = formY

    dispatch("propose", { x: formX, y: formY, rotation: formRotation })
  }

  function handleAccept(e) {
    dispatch("accept")
  }

  document
    .querySelector("[name='storage_unit_type[site_map_name_rotation]']")
    .addEventListener("input", (e) => {
      rotation = Number(e.target.value)
      handlePropose()
    })
</script>

<form bind:this={formEl} class="hidden" on:submit|preventDefault={handleAccept}>
  <label for="unitNamePositionX">PositionX</label>
  <input
    type="text"
    id="unitNamePositionX"
    value={x}
    on:change={handlePropose}
  />
  <label for="unitNamePositionY">PositionY</label>
  <input
    type="text"
    id="unitNamePositionY"
    value={y}
    on:change={handlePropose}
  />

  <button type="submit" class="btn"> Accept </button>
  <button type="button" class="btn" on:click={() => dispatch("cancel")}>
    Cancel
  </button>
</form>
