import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["range"]

  rotate = (e) => {
    e.preventDefault();
    const val = this.rangeTarget.value;
    let newSnap = 0
    if (val < 90) {
      newSnap = 90
    } else if (val < 180) {
      newSnap = 180
    } else if (val < 270) {
      newSnap = 270
    } else {
      newSnap = 0
    }

    this.rangeTarget.value = newSnap
    const ev = document.createEvent('HTMLEvents');
    ev.initEvent("input", false, true);
    this.rangeTarget.dispatchEvent(ev);
  }
}