import { Controller } from "@hotwired/stimulus"
import Editor from "../components/bulk_storage_unit_map/Editor.svelte"
import { get } from "api"

export default class extends Controller {
  connect() {
    get(window.location).then(data => {
      this.editor = new Editor({
        target: this.element,
        props: {
          unit: data.unit,
          activeLeases: data.active_leases,
          updateLeasePositionUrl: data.update_lease_position_url,
        }
      })
    })
  }

  disconnect() {
    this.editor.$destroy()
  }
}
