import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "list"]

  onChange = (e) => {
    const content = this.templateTarget.content
    const list = this.listTarget
    list.innerHTML = ""

    for (const file of e.currentTarget.files) {
      const row = document.importNode(content, true)

      // Set filename
      row.querySelector("span").innerText = file.name

      // Set photo preview
      const src = URL.createObjectURL(file);
      row.querySelector("img").src = src

      list.appendChild(row)
    }
  }
}