import { Controller } from "@hotwired/stimulus"
import { isDebug } from "utils";

export default class extends Controller {
  disabled() {
    return isDebug()
  }

  disconnect() {
    document.removeEventListener("keydown", this.escapeKey)
  }

  hideWidget() {
    window.supportHeroWidget.hide();
    const container = document.querySelector("#supporthero-container")
    container.style.opacity = 0;
    container.style.display = "none";
    container.classList.add("opacity-0")
    container.classList.remove("opacity-100")
    document.removeEventListener("keydown", this.escapeKey)
    document.body.classList.remove("overflow-hidden");
  }

  showWidget() {
    const container = document.querySelector("#supporthero-container")
    document.body.classList.add("overflow-hidden");
    container.style.removeProperty("opacity")
    container.style.removeProperty("display")
    container.classList.add("opacity-0", "transition-all", "duration-300", "ease-linear", "relative")
    container.addEventListener("click", (e) => {
      if (e.target === container) {
        this.hideWidget()
      }
    })

    setTimeout(() => {
      container.classList.add("opacity-100")
      container.classList.remove("opacity-0")
    }, 5)

    const frame = document.querySelector("#supporthero-container iframe")
    frame.style.height = "100%";
    frame.style.minWidth = "50%";
    frame.classList.add("transition-all", "duration-300", "ease-in-out", "shadow-2xl", "translate-x-full", "delay-200")
    setTimeout(() => {
      frame.classList.remove("translate-x-full")
      frame.classList.add("translate-x-0")
    }, 5)


    const close = document.querySelector("#supporthero-hide")
    close.style.removeProperty("top")
    close.style.removeProperty("opacity")
    close.style.removeProperty("right")
    close.style.removeProperty("display")
    close.classList.add("top-4", "right-4")
    document.addEventListener("keydown", this.escapeKey)
  }

  escapeKey = (e) => {
    if (e.key === "Escape") {
      console.log("clsing")

      this.hideWidget()
    }
  }

  faq(e) {
    if (this.disabled()) { return }

    e.preventDefault();
    this.showWidget()
    window.supportHeroWidget.show();
  }

  connect() {
    if (this.disabled()) { return }

    if (!window.supportHeroWidget) {
      const src = "https://d29l98y0pmei9d.cloudfront.net/js/widget.min.js?k=Y2xpZW50SWQ9NjEzMSZob3N0TmFtZT1jY3N0b3JhZ2Uuc3VwcG9ydGhlcm8uaW8="
      const script = document.createElement('script');
      script.async = true;
      script.dataset.cfasync = "false";
      script.dataset.turboTemporary = "true";
      script.src = src;
      document.head.appendChild(script);

      script.onload = () => {
        window.supportHeroWidget.onHide(() => {
          this.hideWidget()
        });
      }
    }
  }

  disconnect() {
    window.supportHeroWidget = undefined
  }
}