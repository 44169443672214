import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { fill: String }

  copyToClipboard(textToCopy) {
    navigator.clipboard.writeText(textToCopy)
  }

  copy(event) {
    event.preventDefault()
    const text = event.currentTarget.dataset.copy
    this.copyToClipboard(text)
  }

  fill(event) {
    event.preventDefault()
    const text = event.currentTarget.dataset.copy
    const input = document.querySelector(this.fillValue)
    if (input) {
      input.value = text
    }
  }
}

