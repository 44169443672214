<script>
  import { getContext } from 'svelte'

  const { xScale, yScale, xDomain, yDomain, xRange, yRange } = getContext('LayerCake')

  export let size = 1

  function generateSeries(min, max, step) {
    let array = []
    for (let i = min; i <= max; i += step) {
      array.push(i)
    }
    return array
  }

  $: xLines = generateSeries($xDomain[0], $xDomain[1], size)
  $: yLines = generateSeries($yDomain[1], $yDomain[0], size)

</script>

<g
  class="stroke-gray-400"
>
  {#each xLines as x}
    <line
      x1={$xScale(x)}
      y1={$yScale($yDomain[0])}
      x2={$xScale(x)}
      y2={$yScale($yDomain[1])}
    />
  {/each}

  {#each yLines as y}
    <line
      x1={$xScale($xDomain[0])}
      y1={$yScale(y)}
      x2={$xScale($xDomain[1])}
      y2={$yScale(y)}
    />
  {/each}
</g>
