import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label"]

  change(e) {
    const button = e.currentTarget
    document.querySelector("#start_date").value = button.dataset.start
    document.querySelector("#end_date").value = button.dataset.end
    document.querySelector("#start_date").closest("form").requestSubmit()
    document.body.click()
    this.labelTarget.innerText = button.innerText
  }

  connect() {
    const start = document.querySelector("#start_date").value
    const end = document.querySelector("#end_date").value
    const button = document.querySelector(`[data-start='${start}'][data-end='${end}']`)
    if (button) {
      this.labelTarget.innerText = button.innerText.trim()
    }
  }
}
