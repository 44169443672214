<script>
  import {
    getCustomerSiteMap,
    getCustomerSiteMapUnitStatuses,
  } from "api/site_maps"
  import { onMount, setContext } from "svelte"
  import { pinch } from "svelte-gestures"
  import { spring } from "svelte/motion"
  import { writable } from "svelte/store"
  import { fade } from "svelte/transition"
  import { getCookie, setCookie } from "utils"
  import MapView from "./MapView.svelte"

  export let siteMapId

  let debugStore = { enabled: false }
  function updateDebugStore(options) {
    debugStore = { ...debugStore, ...options }
  }
  setContext("updateDebugStore", updateDebugStore)

  const sitemapStatuses = writable({})
  setContext("sitemapStatuses", sitemapStatuses)

  let selectedUnit = ""
  let selectedUnitRect = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: 0,
    width: 0,
  }
  function setSelectedUnit(unitId, rect) {
    selectedUnit = unitId
    if (rect) {
      selectedUnitRect = rect
    }
  }
  setContext("setSelectedUnit", setSelectedUnit)

  onMount(() => load(true))

  let status = "loading"
  let loadError
  let storageUnitTypes
  let storageUnitTypesById
  let storageUnitRows
  let storageUnitRowsById
  let unassignedStorageUnits
  let mapWidth
  let mapHeight
  let mapBackgroundImageUrl

  const minViewWidth = 50
  let defaultZoom = getCookie(`${siteMapId}zoom`)
  if (defaultZoom) {
    defaultZoom = Number(defaultZoom)
  }
  let defaultYCenter = getCookie(`${siteMapId}viewYCenter`)
  if (defaultYCenter) {
    defaultYCenter = Number(defaultYCenter)
  }
  let defaultXCenter = getCookie(`${siteMapId}viewXCenter`)
  if (defaultXCenter) {
    defaultXCenter = Number(defaultXCenter)
  }

  let viewWidth = spring(defaultZoom || minViewWidth)
  let viewXCenter = defaultXCenter || $viewWidth / 2
  let viewYCenter = defaultYCenter || viewXCenter

  let selectedStorageUnitRowId

  async function load(firstLoad = false) {
    try {
      if (firstLoad) {
        const unitStatuses = await getCustomerSiteMapUnitStatuses(siteMapId)
        if (unitStatuses.ok) {
          sitemapStatuses.set(unitStatuses.units)
        }
      }

      const siteMap = await getCustomerSiteMap(siteMapId)
      storageUnitTypes = siteMap.storageUnitTypes
      for (const storageUnitType of storageUnitTypes) {
        storageUnitType.shape.width = Math.max(
          ...storageUnitType.shape.map((p) => p[0])
        )
        storageUnitType.shape.height = Math.max(
          ...storageUnitType.shape.map((p) => p[1])
        )
      }
      storageUnitTypesById = new Map(
        siteMap.storageUnitTypes.map((sut) => [sut.id, sut])
      )

      storageUnitRows = siteMap.storageUnitRows
      storageUnitRowsById = new Map(
        siteMap.storageUnitRows.map((sur) => [sur.id, sur])
      )

      unassignedStorageUnits = siteMap.unassignedStorageUnits
      mapWidth = siteMap.width
      mapHeight = siteMap.height
      mapBackgroundImageUrl = siteMap.backgroundImageUrl

      if (firstLoad) {
        if ($viewWidth === minViewWidth) {
          $viewWidth = mapWidth
        }
        if (!defaultXCenter) {
          viewXCenter = $viewWidth / 2
        }
        if (!defaultYCenter) {
          viewYCenter = viewXCenter
        }
      }

      setTimeout(() => {
        status = "ready"
      }, 500)
    } catch (error) {
      loadError = error.message
      setTimeout(() => {
        status = "loadFailed"
      }, 500)
    }
  }

  let dragState
  let spacePressed = false
  let panState

  function handleDomainPointerDown(e) {
    if (e.detail.entityType !== "storageUnitRow") {
      panState = {
        startElementX: e.detail.elementX,
        startElementY: e.detail.elementY,
        startViewXCenter: viewXCenter,
        startViewYCenter: viewYCenter,
        startXScale: e.detail.xScale.copy(),
        startYScale: e.detail.yScale.copy(),
      }
    } else {
      if (e.detail.entityType !== "storageUnitRow") {
        selectedStorageUnitRowId = null
      }
    }
  }

  async function handleDomainPointerUp(e) {
    panState = null

    if (dragState) {
      if (
        dragState.startX != dragState.currentX &&
        dragState.startY != dragState.currentY
      ) {
        try {
          const storageUnitRow = storageUnitRowsById.get(
            dragState.storageUnitRowId
          )

          const response = await updateSiteMapRow(
            siteMapId,
            dragState.storageUnitRowId,
            storageUnitRow.position
          )
          if (!response.ok) {
            throw new Error("Failed to save storage unit row position")
          }
        } catch (error) {
          status = "saveFailed"
          loadError = error.message
        }
      }

      dragState = null
    } else {
      selectedStorageUnitRowId = null
    }
  }

  function handleDomainPointerMove(e) {
    if (panState) {
      const offsetX =
        panState.startXScale.invert(e.detail.elementX) -
        panState.startXScale.invert(panState.startElementX)
      const offsetY =
        panState.startYScale.invert(e.detail.elementY) -
        panState.startYScale.invert(panState.startElementY)

      viewXCenter = panState.startViewXCenter - offsetX
      viewYCenter = panState.startViewYCenter - offsetY

      setCookie(`${siteMapId}viewYCenter`, viewYCenter, 365)
      setCookie(`${siteMapId}viewXCenter`, viewXCenter, 365)
    } else if (dragState) {
    }
  }

  function zoomMap(multiplier) {
    const maxViewWidth = mapWidth * 5
    const newWidth = $viewWidth * multiplier
    if (newWidth < minViewWidth || newWidth > maxViewWidth) {
      return
    }

    $viewWidth = newWidth
    setCookie(`${siteMapId}zoom`, $viewWidth, 365)
  }

  function zoomOut() {
    zoomMap(1.35)
  }

  function zoomIn() {
    zoomMap(0.7)
  }

  function zoomReset() {
    $viewWidth = mapWidth * 3
    zoomMap(1)
  }

  function handleKeyDown(event) {
    if (event.key === " ") {
      event.preventDefault()
      spacePressed = true
    } else if (event.key === "=" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      zoomIn()
    } else if (event.key === "-" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      zoomOut()
    } else if (event.key === "0" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      zoomReset()
    } else if (event.key === "d") {
      debugStore.enabled = !debugStore.enabled
    }
  }

  function handleKeyUp(event) {
    if (event.key === " ") {
      event.preventDefault()
      spacePressed = false
    }
  }

  $: updateDebugStore({
    viewXCenter: viewXCenter.toFixed(4),
    viewYCenter: viewYCenter.toFixed(4),
    $viewWidth: $viewWidth.toFixed(4),
  })
</script>

<svelte:window on:keydown={handleKeyDown} on:keyup={handleKeyUp} />
<svelte:body on:mouseup={handleDomainPointerUp} />

{#if status == "ready"}
  <div
    transition:fade
    class="bg-gray-100 h-[70vh] w-full flex drop-shadow z-10 select-none"
  >
    {#if debugStore.enabled}
      <div
        transition:fade
        class="rounded-lg p-3 bg-black/50 backdrop-blur text-white absolute z-10 top-4 right-4 max-w-[300px]"
      >
        {#each Object.entries(debugStore) as [key, value]}
          <div>{key}: {value}</div>
        {/each}
      </div>
    {/if}

    <div
      role="application"
      class="flex-1 overflow-hidden"
      use:pinch
      on:pinch={() => {}}
      on:focus={() => {}}
      on:mouseover={(e) => {
        setSelectedUnit("")
      }}
    >
      <MapView
        {storageUnitTypesById}
        {storageUnitRows}
        {selectedStorageUnitRowId}
        {mapWidth}
        {mapHeight}
        {mapBackgroundImageUrl}
        {viewXCenter}
        {viewYCenter}
        viewWidth={$viewWidth}
        {spacePressed}
        on:domainpointerdown={handleDomainPointerDown}
        on:domainpointermove={handleDomainPointerMove}
      />

      <div
        class="absolute bottom-4 right-4 bg-black/50 backdrop-blur shadow-lg rounded-lg text-white overflow-clip"
      >
        <div class="rounded-xl divide-y">
          <button
            on:click={(e) => {
              e.preventDefault()
              zoomReset()
            }}
            class="hover:bg-black/20 cursor-pointer w-10 h-10 flex items-center justify-center text-2xl"
          >
            <svg class="w-5 h-5 text-white-500">
              <svg
                id="arrow-path"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                  clip-rule="evenodd"
                />
              </svg>
            </svg>
          </button>
          <button
            on:click={(e) => {
              e.preventDefault()
              zoomIn()
            }}
            class="hover:bg-black/20 cursor-pointer p-4 w-10 h-10 flex items-center justify-center text-2xl"
          >
            +
          </button>
          <button
            on:click={(e) => {
              e.preventDefault()
              zoomOut()
            }}
            class="hover:bg-black/20 cursor-pointer p-4 w-10 h-10 flex items-center justify-center text-2xl"
          >
            -
          </button>
        </div>
      </div>
    </div>
  </div>
{:else if status != "loading"}
  <div transition:fade class="flex h-full items-center justify-center">
    <div>
      <div class="flex items-center justify-center">
        <svg class="w-8 h-8 text-red-500 mr-2">
          <svg
            id="exclamation-circle"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clip-rule="evenodd"
            />
          </svg>
        </svg>
        <p class="text-red-500 text-3xl text-center">Error</p>
      </div>

      <p class="text-red-500 text-xl text-center">
        {loadError}
      </p>
    </div>
  </div>
{/if}

{#if status == "loading"}
  <div transition:fade class="flex h-full items-center justify-center">
    <svg
      class="animate-spin -ml-1 mr-3 h-8 w-8 text-slate-700"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
    <p class="text-slate-700 text-3xl">Site map loading...</p>
  </div>
{/if}
