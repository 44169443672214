<script>
  import Moveable from "svelte-moveable"
  import { debounce } from "utils"

  export let unit
  export let url
  export let edit
  export let cullText = false

  const maxWidth = "auto"
  const maxHeight = "auto"
  const minWidth = "auto"
  const minHeight = "auto"
  const draggable = true
  const throttleDrag = 1
  const edgeDraggable = false
  const startDragRotate = 0
  const throttleDragRotate = 0
  const resizable = true
  const keepRatio = false
  const throttleResize = 1
  const renderDirections = ["nw", "n", "ne", "w", "e", "sw", "s", "se"]
  const rotatable = true
  const throttleRotate = 0
  const rotationPosition = "top"
  let targetRef = null
  let moveableRef = null

  const submitForm = debounce(() => {
    document.querySelector("form#bulk_storage_site_map_upsert").requestSubmit()
  }, 500)

  function savePosition(width, height, transform) {
    const json = { width, height, transform }
    updateForm(JSON.stringify(json))
    submitForm()
  }

  function updateForm(value) {
    const dataField = document.querySelector(
      "form#bulk_storage_site_map_upsert input#bulk_storage_unit_site_map_data"
    )
    dataField.value = value
  }

  function onlyNumbers(str) {
    return str.replace(/\D/g, "")
  }

  $: bg = url.length ? `background-image: url(${url});` : ""
</script>

<div
  role="cell"
  tabindex="-1"
  class="bg-origin-border bg-center bg-contain bg-no-repeat target absolute flex items-center justify-center text-center bg-slate-400 {edit
    ? 'cursor-grab'
    : ''}"
  bind:this={targetRef}
  style={`${bg} transform: ${unit.data.transform}; width: ${unit.data.width}px; height: ${unit.data.height}px; max-width: ${maxWidth};max-height: ${maxHeight};min-width: ${minWidth};min-height: ${minHeight};`}
  on:mousedown={() => {
    if (!edit) return
    targetRef.classList.remove("cursor-grab")
    targetRef.classList.add("cursor-grabbing")
  }}
  on:mouseup={() => {
    if (!edit) return
    targetRef.classList.add("cursor-grab")
    targetRef.classList.remove("cursor-grabbing")
  }}
>
  <span
    class="transition-opacity duration-500 {cullText
      ? 'opacity-0'
      : 'opacity-100'}"
  >
    {unit.name}
  </span>
</div>
{#if edit}
  <Moveable
    bind:this={moveableRef}
    target={targetRef}
    {draggable}
    {throttleDrag}
    {edgeDraggable}
    {startDragRotate}
    {throttleDragRotate}
    {resizable}
    {keepRatio}
    {throttleResize}
    {renderDirections}
    {rotatable}
    {throttleRotate}
    {rotationPosition}
    on:drag={({ detail: e }) => {
      e.target.style.transform = e.transform
      savePosition(
        onlyNumbers(e.target.style.width),
        onlyNumbers(e.target.style.height),
        e.transform
      )
    }}
    on:resize={({ detail: e }) => {
      e.target.style.width = `${e.width}px`
      e.target.style.height = `${e.height}px`
      e.target.style.transform = e.drag.transform
      savePosition(e.width, e.height, e.drag.transform)
    }}
    on:rotate={({ detail: e }) => {
      e.target.style.transform = e.drag.transform
      savePosition(
        onlyNumbers(e.target.style.width),
        onlyNumbers(e.target.style.height),
        e.drag.transform
      )
    }}
  />
{/if}
