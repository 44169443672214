import { Controller } from "@hotwired/stimulus";

const MB_15 = 15720000;
const MB_1 = 1050000;

// function bytesToSize(bytes: number) {
function bytesToSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export default class extends Controller {
  static targets = ["fileInput", "removeContent"];

  // declare fileInputTarget: HTMLInputElement;
  // declare removeContentTarget: HTMLInputElement;

  // imagePreview(e: Event) {
  imagePreview(e) {
    const target = e.target //as HTMLInputElement;
    const max = target.dataset.limit || MB_15;
    const files = target.files || [];
    if (files.length > 0) {
      const file = files[0];
      // if (file.size > Number(max)) {
      //   const text = `File size too large. Maximum size is ${bytesToSize(
      //     Number(max)
      //   )}, while your file is ${bytesToSize(file.size)}.`;
      //   // window.toast({ text, type: "error" });
      //   target.value = "";
      //   e.preventDefault();
      //   return;
      // }
      const src = URL.createObjectURL(file);
      const div = this.element.querySelector(".preview-container")
      div?.classList?.remove("hidden");
      const preview = div.querySelector("img") //as HTMLImageElement;
      div.nextSibling?.classList?.add("hidden");
      div.nextSibling?.nextSibling?.classList?.add("hidden");
      div.nextElementSibling?.nextElementSibling?.classList?.add("hidden");

      if (preview) {
        preview.src = src;
      }
    }
  }

  // removeLogo(e: Event) {
  removeLogo(e) {
    e.preventDefault();
    window
      .confirmMethod("Remove Logo?", e.currentTarget)
      .then((yes) => {
        if (yes) {
          this.removeContentTarget.checked = true;
          this.fileInputTarget.value = "";
          this.fileInputTarget.files = null;
          this.fileInputTarget.closest("form")?.requestSubmit();
        }
      });
  }

  // fileAttached({ target }: { target: HTMLInputElement }) {
  fileAttached({ target }) {
    const files = target.files || [];
    if (files.length > 0) {
      this.removeContentTarget.checked = false;
    }
  }
}

