import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog"]

  close = (e) => {
    e.preventDefault();
    this.closeDialog();
  };

  closeDialog = () => {
    const dialog = this.dialogTarget
    this.resetClasses(dialog)
    setTimeout(() => {
      dialog.close()
    }, 500)
  }

  open = (e) => {
    e?.preventDefault();
    document.body.classList.add("overflow-hidden");
    const dialog = this.dialogTarget
    dialog.showModal();
    dialog.classList.remove("backdrop:opacity-0", "opacity-0")
    dialog.classList.add("backdrop:opacity-100", "opacity-100")
  }

  resetClasses = (dialog) => {
    document.body.classList.remove("overflow-hidden");
    dialog.classList.add("backdrop:opacity-0", "opacity-0")
    dialog.classList.remove("backdrop:opacity-100", "opacity-100")
  }

  connect() {
    const dialog = this.dialogTarget
    dialog.addEventListener("close", () => {
      this.resetClasses(dialog)
    });

    if (dialog.hasAttribute("data-auto-open")) {
      this.open()
    }
  }

  disconnect() {
    if (this.dialogTarget.open) {
      this.resetClasses(this.dialogTarget)
      this.dialogTarget.close()
    }
  }
}
