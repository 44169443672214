import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  focus(e) {
    const focusedTag = document.activeElement.tagName.toLowerCase()
    if (focusedTag !== "input" && focusedTag !== "textarea") {
      e.preventDefault()
      this.element.focus()
    }
  }
}
