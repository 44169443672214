import { writable } from "svelte/store"
import { debounce } from "utils"

function saveZoom(id, scale) {
  localStorage.setItem(`${id}-zoom`, scale)
}

function loadZoom(id) {
  const savedZoom = localStorage.getItem(`${id}-zoom`)
  let zoom = 1.0
  if (savedZoom) {
    zoom = Number(savedZoom)
  }

  return zoom
}

function loadTransform(id) {
  const savedTransform = localStorage.getItem(`${id}-transform`)
  return savedTransform ? savedTransform : undefined
}

function saveTransform(id, transform) {
  localStorage.setItem(`${id}-transform`, transform)
}


export function createZoom(id) {
  const defaultZoom = loadZoom(id)
  const defaultTransform = loadTransform(id)
  const data = {
    scale: defaultZoom,
    transform: defaultTransform,
  }
  const { subscribe, set, update } = writable(data);

  return {
    subscribe,
    in: (s = 0.2) => {
      update(({ scale, transform }) => {
        let newZoom = Math.min(scale + s, 4)
        newZoom = Math.max(newZoom, 0.3)
        if (newZoom > 2) {
          newZoom = 2
        }
        saveZoom(id, newZoom)
        return { scale: newZoom, transform }
      })
    },
    out: (s = -0.2) => update(({ scale, transform }) => {
      const newZoom = Math.max(scale + s, 0.3)
      saveZoom(id, newZoom)
      return { scale: newZoom, transform }
    }),
    updateTransform: (newTransform) => update(({ scale }) => {
      debounce(saveTransform(id, newTransform), 500)
      return { scale, transform: newTransform }
    }),
    reset: (s, t) => {
      let scale = s || 1.0
      let transform = t || ""
      saveZoom(id, scale)
      saveTransform(id, transform)
      set({ scale, transform })
    }
  };
}
