import { Controller } from "@hotwired/stimulus"
import { numberToCurrency } from "utils"

export default class extends Controller {
  static targets = ["text", "amount"]

  updateButton() {
    this.textTarget.textContent = `Pay ${numberToCurrency(this.amountTarget.value)}`
    if (this.amountTarget.value >= 1) {
      this.textTarget.closest("button").removeAttribute("disabled")
    } else {
      this.textTarget.closest("button").setAttribute("disabled", "disabled")
    }
  }

  updateAmount(e) {
    const number = Number(e.currentTarget.value)
    this.amountTarget.value = number
    this.updateButton()
  }

  connect() {
    this.updateButton()
  }
}