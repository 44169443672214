import { Controller } from "@hotwired/stimulus"
import Editor from "../components/storage_unit_type_shape/Editor.svelte"

export default class extends Controller {
  static values = {
    storageUnitTypeJson: Object,
  }

  connect() {
    this.editor = new Editor({
      target: this.element,
      props: {
        storageUnitType: this.storageUnitTypeJsonValue
      }
    })
  }

  disconnect() {
    this.editor.$destroy()
  }
}
