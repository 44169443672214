<script>
  import { getContext } from "svelte"

  export let namePosition
  export let nameRotation
  export let pointerDown

  const { xScale, yScale } = getContext("LayerCake")
</script>

<g
  on:pointerdown={pointerDown}
  class="cursor-move select-none"
  transform={`translate(${$xScale(namePosition.x)}, ${$yScale(
    namePosition.y
  )}) rotate(${nameRotation})`}
>
  <text
    class="fill-white hover:fill-slate-200 font-display font-bold"
    text-anchor="middle">Unit Name</text
  >

  <line
    class="stroke-white stroke-2"
    x1={-48}
    y1={4}
    x2={48}
    y2={4}
    stroke-dasharray="2"
  />
</g>
