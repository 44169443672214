import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "answer"]

  preview() {
    this.previewTarget.querySelector("dt").innerText = this.element.querySelector("input[type='text']").value
    this.previewTarget.querySelector("dd").innerHTML = this.element.querySelector("textarea").value
  }

  insertLink(e) {
    e.preventDefault()
    this.element.querySelector("textarea").value += `<a href="(url)" target="_blank" class="link">Link</a>`
    this.preview()
  }
}
