
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  format(e) {
    if (this.element.dataset.numeric) {
      let cleaned = "" + e.currentTarget.value
      const max = Number(this.element.dataset.max)
      cleaned = cleaned.replace(/\D/g, '');
      if (cleaned.length > max) {
        cleaned = cleaned.substring(0, max)
      }

      e.currentTarget.value = cleaned
      return
    }
  }

  connect() {
    this.format({ currentTarget: this.element })
    this.element.dataset.action += " input->formatter#format"
  }
}