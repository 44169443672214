<script>
  import { getContext } from "svelte"

  const { xScale, yScale, width, height } = getContext("LayerCake")

  export let mapWidth
  export let mapHeight
  export let mapBackgroundImageUrl

  $: screenWidth = $xScale(mapWidth) - $xScale(0)
  $: screenHeight = $yScale(mapHeight) - $yScale(0)
</script>

<div
  class="bg-gray-200"
  style:width="{$width}px"
  style:height="{$height}px"
  style:background-image="url({mapBackgroundImageUrl})"
  style:background-size="{screenWidth}px {screenHeight}px"
  style:background-position="{$xScale(0)}px {$yScale(0)}px"
  style:background-repeat="no-repeat"
/>
