import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["remove"]

  clear = () => {
    setTimeout(() => {
      this.removeTarget.innerHTML = ""
    }, 300);
  }
}
