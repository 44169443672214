import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  update(e) {
    this.inputTarget.value = e.target.selectedOptions[0].dataset.price
    this.inputTarget.classList.add("animate-pulse")
    setTimeout(() => {
      this.inputTarget.classList.remove("animate-pulse")
    })
  }

  connect() {
    this.update({ target: this.element.querySelector("select") })
  }
}
