import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const dialog = this.element.closest("dialog")
    if (dialog) {
      if (dialog.dataset.controller === "dialog") {
        dialog.querySelector("[data-action='click->dialog#open']").click()
      } else {
        dialog.showModal();
      }
    }
  }
}