import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  reloadSummary() {
    const url = `${window.location.href.replace("/new", "/summary.turbo_stream")}&${this.buildUrlParams()}`
    document.getElementById("summary").src = url
  }

  reloadSummaryWithAchPricing() {
    const url = `${window.location.href.replace("/new", "/summary.turbo_stream")}&${this.buildUrlParams("ach")}`
    document.getElementById("summary").src = url
  }

  reloadSummaryWithCardPricing() {
    const url = `${window.location.href.replace("/new", "/summary.turbo_stream")}&${this.buildUrlParams("credit")}`
    document.getElementById("summary").src = url
  }

  reloadProtectionPlan() {
    const turbo_frame = document.getElementById("protection_plan")
    if (!turbo_frame) return

    const url = `${window.location.href.replace("/new", "/protection_plan_section.turbo_stream")}&${this.buildUrlParams()}`
    turbo_frame.src = url
  }

  reloadProtectionPlanWithAchPricing() {
    const turbo_frame = document.getElementById("protection_plan")
    if (!turbo_frame) return

    const url = `${window.location.href.replace("/new", "/protection_plan_section.turbo_stream")}&${this.buildUrlParams("ach")}`
    turbo_frame.src = url
  }

  reloadProtectionPlanWithCardPricing() {
    const turbo_frame = document.getElementById("protection_plan")
    if (!turbo_frame) return

    const url = `${window.location.href.replace("/new", "/protection_plan_section.turbo_stream")}&${this.buildUrlParams("credit")}`
    turbo_frame.src = url
  }

  reloadDiscount(e) {
    e.preventDefault()

    const turbo_frame = document.getElementById("discount")
    if (!turbo_frame) return

    const discount_code_input = document.querySelector("input[name='discount_code']")
    if (!discount_code_input || !discount_code_input.value.trim()) return

    const url = `${window.location.href.replace("/new", "/discount_application.turbo_stream")}&${this.buildUrlParams()}`
    turbo_frame.src = url
  }

  buildUrlParams(pricingType = null) {
    const params = new URLSearchParams()

    if (pricingType) {
      params.append("payment_method", pricingType)
    } else if (document.querySelector("#cc") && document.querySelector("#cc").checked) {
      params.append("payment_method", "credit")
    } else if (document.querySelector("#ach") && document.querySelector("#ach").checked) {
      params.append("payment_method", "ach")
    }

    const billing_interval_id = document.querySelector("#rental_billing_interval_id").value
    params.append("rental[billing_interval_id]", billing_interval_id)

    const selected_protection_plan_input = document.querySelector("input[name='rental[protection_plan_option_id]']:checked")
    if (selected_protection_plan_input) {
      params.append("rental[protection_plan_option_id]", selected_protection_plan_input.value)
    }
    const property_description_input = document.querySelector("#rental_property_description")
    if (property_description_input) {
      params.append("rental[property_description]", property_description_input.value)
    }
    const insurance_file_input = document.querySelector("fieldset > input[name='rental[insurance_file]']")
    if (insurance_file_input) {
      params.append("rental[insurance_file]", insurance_file_input.value)
    }

    const discount_code_input = document.querySelector("input[name='discount_code']")
    if (discount_code_input) {
      params.append("discount_code", discount_code_input.value)
    }
    const discount_id_input = document.querySelector("input[name='rental[discount_id]']")
    if (discount_id_input) {
      params.append("rental[discount_id]", discount_id_input.value)
    }

    return params.toString()
  }
}
