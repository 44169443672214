<script>
  import { createEventDispatcher } from "svelte"

  const dispatch = createEventDispatcher()

  export let storageUnits

  let dragState = {}

  function handleDragStart(e, storageUnit) {
    e.dataTransfer.effectAllowed = "move"
    e.dataTransfer.setData("ccstorage/storage-unit", `${storageUnit.id}`)
    dragState = {
      draggedStorageUnit: storageUnit,
    }
  }

  function handleDragOver(e, storageUnit) {
    dragState.hoveredStorageUnit = storageUnit
    if (dragState.draggedStorageUnit.id === storageUnit.id) {
      e.dataTransfer.dropEffect = "none"
      return
    }
    e.preventDefault()
    e.dataTransfer.dropEffect = "move"

    if (e.offsetY > e.target.offsetHeight / 2) {
      dragState.hoveredTop = false
      dragState.hoveredBottom = true
    } else if (e.offsetY < e.target.offsetHeight / 2 && e.offsetY >= 0) {
      dragState.hoveredTop = true
      dragState.hoveredBottom = false
    } else {
      dragState.hoveredTop = false
      dragState.hoveredBottom = false
    }
  }

  function handleDragEnd(e, storageUnit) {
    dragState = {}
  }

  function handleDrop(e, storageUnit) {
    let storageUnitIds = []
    for (let su of storageUnits) {
      if (su === dragState.draggedStorageUnit) {
        continue
      }

      if (su === storageUnit) {
        if (dragState.hoveredTop) {
          storageUnitIds.push(dragState.draggedStorageUnit.id)
          storageUnitIds.push(su.id)
        } else if (dragState.hoveredBottom) {
          storageUnitIds.push(su.id)
          storageUnitIds.push(dragState.draggedStorageUnit.id)
        }
      } else {
        storageUnitIds.push(su.id)
      }
    }

    dispatch("reorderstorageunits", { storageUnitIds })
  }

  function dragClass(storageUnit) {
    if (dragState.draggedStorageUnit === storageUnit) {
      return "opacity-50 rounded-lg"
    } else if (dragState.hoveredStorageUnit === storageUnit) {
      if (dragState.hoveredTop) {
        return "border-t-green-500"
      } else if (dragState.hoveredBottom) {
        return "border-b-green-500"
      }
    }
  }

  function reverse() {
    const storageUnitIds = storageUnits.reverse().map(su => su.id)
    dispatch("reorderstorageunits", { storageUnitIds })
  }
</script>

<div class="rounded-xl overflow-hidden">
  <div class="p-2 border-b flex justify-between items-center">
    Sort Units:
    <button on:click={reverse} class="bg-white hover:bg-gray-100 rounded-xl border px-3 py-2 text-slate-700 text-sm">
      Reverse
    </button>
  </div>
  <ul class="divide-y">
    {#each storageUnits as storageUnit (storageUnit.id)}
      <li
        class={`p-2 bg-white hover:bg-gray-100 hover:cursor-grab ${dragClass(storageUnit, dragState)}`}
        draggable="true"
        on:dragstart={(e) => handleDragStart(e, storageUnit)}
        on:dragover={(e) => handleDragOver(e, storageUnit)}
        on:dragend={(e) => handleDragEnd(e, storageUnit)}
        on:drop={(e) => handleDrop(e, storageUnit)}
      >
        {storageUnit.name}
      </li>
    {/each}
  </ul>
</div>
