import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  setHeight(e) {
    this.#setHeight(e.currentTarget) // HTMLTextAreaElement
  }

  // #setHeight(textArea: HTMLTextAreaElement) { 
  #setHeight(textArea) {
    textArea.setAttribute("style", "height: 0px");
    textArea.setAttribute("style", "height:" + textArea.scrollHeight + "px;");
  }

  connect() {
    // this.element as HTMLTextAreaElement
    this.#setHeight(this.element);
  }
}
