// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>


// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.glob('./**/*_channel.js', {eager: true})

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "@hotwired/turbo-rails"

import { Application, defaultSchema } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'
import { Alert, Dropdown } from "tailwindcss-stimulus-components"
import Tabs from "../controllers/monkey_tabs_controller"
import * as Sentry from "@sentry/svelte";

const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, slash: "/" },
}
const application = Application.start(document.documentElement, customSchema)

const isDebug = !window.location.hostname.includes("ccstorage.com")
if (!isDebug) {
  Sentry.init({
    dsn: "https://709ebe2db6dc41c0965a9c6b6670f9bb@o103799.ingest.sentry.io/294937",
  });
}

// Configure Stimulus development experience
window.Stimulus = application
window.Stimulus.debug = isDebug
window.Stimulus.register("alert", Alert)
window.Stimulus.register("dropdown", Dropdown)
window.Stimulus.register("tabs", Tabs)

const controllers = import.meta.glob(['../controllers/*_controller.js', '../../components/*_controller.js'], { eager: true })
registerControllers(application, controllers)

const confirmMethod = (title, element, body = null) => {
  return new Promise((resolve) => {
    const dialogTemplate = document.querySelector(
      "template#confirm_template"
    );
    const temp = document.importNode(dialogTemplate.content, true);
    const dialog = temp.querySelector("dialog");
    const destructiveButton = dialog.querySelector("[data-destructive=true]")
    const defaultButton = dialog.querySelector("[data-default=true]")
    if (element.dataset.style === "destructive") {
      defaultButton.remove()
    } else {
      destructiveButton.remove()
    }

    dialog.dataset.confirmDialogTitleValue = title || "Confirm";
    dialog.dataset.confirmDialogBodyValue =
      element.dataset.body || body || "Are you sure?";

    dialog.dataset.confirmDialogButtonValue = element.dataset.button || "OK";


    dialog.addEventListener("close", () => {
      resolve(dialog?.returnValue === "confirm");
    });
    document.body.appendChild(temp);
  });
};

window.confirmMethod = confirmMethod;
window.Turbo.setConfirmMethod(confirmMethod);


let sidebarScrollTop = 0;
document.addEventListener("turbo:before-visit", function () {
  sidebarScrollTop =
    document.querySelector('[data-sidebar-target="sidebar"]')?.scrollTop || 0;
});

document.addEventListener("turbo:render", function () {
  const sel = document.querySelector('[data-sidebar-target="sidebar"]');
  if (sel) sel.scrollTop = sidebarScrollTop;
});
