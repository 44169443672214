<script lang="ts">
  import type { UnitType } from "api/site_maps"
  import { scaleLinear } from "d3-scale"
  import { getContext, onDestroy } from "svelte"

  export let x: number
  export let y: number
  export let storageUnit: { id: string; typeId: string; name: string }
  export let storageUnitRowSelected: boolean
  export let storageUnitTypesById: Map<string, any>

  let map: { [key: string]: UnitType | undefined } = {}
  const statuses = getContext("sitemapStatuses")
  let unsubscribe = () => {}
  if (statuses) {
    // @ts-ignore
    unsubscribe = statuses.subscribe((value) => {
      map = value
    })
  }
  onDestroy(unsubscribe)

  let shape
  let text

  let unitStatus = map[storageUnit.id]
  let color = "fill-black cursor-pointer"
  let bg =
    "stroke-black fill-white stroke group-hover:fill-gray-300 cursor-pointer"

  if (unitStatus?.disabled) {
    color = "fill-white font-bold cursor-pointer"
    bg =
      "transition-colors text-white fill-gray-900/80 stroke-black group-hover:fill-black/60 cursor-pointer"
  }
  if (unitStatus?.occupancyStatus === "occupied") {
    color = "fill-green-800 font-bold cursor-pointer"
    bg =
      "transition-colors fill-green-300 stroke-green-800 group-hover:fill-green-500 cursor-pointer"
  }
  if (unitStatus?.occupancyStatus === "past_due") {
    color = "fill-red-800 font-bold cursor-pointer"
    bg =
      "transition-colors fill-red-300 stroke-4 stroke-red-800 group-hover:fill-red-400 cursor-pointer"
  }
  if (unitStatus?.auction) {
    color = "fill-yellow-700 fill-white font-bold cursor-pointer"
    bg =
      "transition-colors fill-yellow-300 stroke-4 stroke-yellow-700 group-hover:fill-yellow-400 cursor-pointer"
  }
  if (unitStatus?.locked) {
    color = "fill-cyan-700 fill-cyan-700 font-bold cursor-pointer"
    bg =
      "transition-colors fill-cyan-300 stroke-4 stroke-cyan-700 group-hover:fill-cyan-400 cursor-pointer"
  }

  const { xScale, yScale } = getContext("LayerCake")
  $: localXScale = scaleLinear().range([0, $xScale(1) - $xScale(0)])
  $: localYScale = scaleLinear().range([0, $yScale(1) - $yScale(0)])

  $: storageUnitType = storageUnitTypesById.get(storageUnit.typeId)
  $: polygonPoints = storageUnitType.shape
    .map((p) => `${localXScale(x + p[0])},${localYScale(y + p[1])}`)
    .join(" ")

  let setSelectedUnit = getContext("setSelectedUnit") as (
    id: string,
    rect: DOMRect
  ) => void

  function shouldCull(_) {
    const isEditing = /company_admin\/site_maps\/sm_.*\/edit/.test(
      window.location.pathname
    )
    if (isEditing) {
      return false
    }

    const textWidth = text?.getBBox()?.width
    const shapeWidth = shape?.getBBox()?.width
    const textHeight = text?.getBBox()?.height
    const shapeHeight = shape?.getBBox()?.height
    if (!textWidth) {
      return false
    }

    let buffer = 2.5
    let cull =
      textWidth > shapeWidth - buffer || textHeight > shapeHeight - buffer
    if (cull) {
      text?.classList?.add("opacity-0")
      text?.classList?.remove("opacity-100")
    } else {
      text?.classList?.remove("opacity-0")
      text?.classList?.add("opacity-100")
    }

    return cull
  }

  $: ct = shouldCull($xScale(0))
</script>

<g
  role="button"
  class="group"
  tabindex="-1"
  on:keypress={(e) => {
    if (e.key === "Enter") {
      const link = document.querySelector(
        `#customer-popover-${storageUnit.id} a`
      )
      // @ts-ignore
      link?.click()
    }
  }}
  on:mouseleave={(e) => {
    if (setSelectedUnit) {
      setSelectedUnit("", e.currentTarget.getBoundingClientRect())
    }
  }}
  on:click={() => {
    const link = document.querySelector(`#customer-popover-${storageUnit.id} a`)
    // @ts-ignore
    link?.click()
  }}
  on:mouseenter={(e) => {
    if (setSelectedUnit)
      setSelectedUnit(storageUnit.id, e.currentTarget.getBoundingClientRect())
  }}
>
  <polygon
    bind:this={shape}
    class={`${bg} focus:ring-transparent ${
      storageUnitRowSelected ? "stroke-blue-500 stroke-2" : `stroke-black`
    }`}
    points={polygonPoints}
  />

  <text
    bind:this={text}
    role="button"
    x={localXScale(x + storageUnitType.namePosition.x)}
    y={localYScale(y + storageUnitType.namePosition.y)}
    text-anchor="middle"
    dominant-baseline="middle"
    tabindex="-1"
    on:keypress={(e) => {
      if (e.key === "Enter") {
        const link = document.querySelector(
          `#customer-popover-${storageUnit.id} a`
        )
        // @ts-ignore
        link?.click()
      }
    }}
    transform={`rotate(${storageUnitType.nameRotation})`}
    transform-origin={`${localXScale(
      x + storageUnitType.namePosition.x
    )} ${localYScale(y + storageUnitType.namePosition.y)}`}
    on:mouseenter={(e) => {
      if (e.currentTarget.parentElement && setSelectedUnit) {
        setSelectedUnit(
          storageUnit.id,
          e.currentTarget.parentElement.getBoundingClientRect()
        )
      }
    }}
    class={color + " text-xs z-0 transition-opacity duration-500"}
  >
    {storageUnit.name}
  </text>
</g>
