<script>
  import { createEventDispatcher } from "svelte"

  const dispatch = createEventDispatcher()

  export let storageUnits
  export let storageUnitTypesById

  let selection = []
  let lastIndex = 0
  let selected = selection.filter((x) => x).length
  let disabled = selected === 0

  function resetSelection(storageUnits) {
    selection = new Array(storageUnits.length).fill(false)
    selected = selection.filter((x) => x).length
    disabled = selected === 0
  }
  $: resetSelection(storageUnits)

  function handleCreateRowClick() {
    dispatch("createstorageunitrow", {
      storageUnits: storageUnits.filter((_, i) => selection[i]),
    })
  }

  function handleSelectClick(e, i) {
    const shift = e.shiftKey
    if (shift) {
      const start = Math.min(lastIndex, i)
      const end = Math.max(lastIndex, i)
      selection.fill(selection[lastIndex], start, end + 1)
      selection = selection
    } else {
      selection[i] = !selection[i]
    }
    selected = selection.filter((x) => x).length
    disabled = selected === 0

    lastIndex = i
  }

  let submitButton = document.querySelector("[type=submit]")
  let buttonClasses = ""
  if (submitButton) {
    buttonClasses = [...submitButton.classList]
      .filter((c) => c !== "sm:w-auto")
      .join(" ")
  }
</script>

<div
  class="border overflow-clip bg-white rounded-xl flex flex-col shadow font-display absolute bottom-4 top-16 left-4 right-4"
>
  <div class="absolute top-0 left-0 right-0 p-2 z-10 bg-white/50 backdrop-blur">
    Unassigned Units:
  </div>
  <ul class="h-full overflow-scroll pt-12 divide-y pb-20">
    {#each storageUnits as unit, i (unit.id)}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <li
        class="cursor-pointer hover:bg-gray-100"
        on:click={(e) => handleSelectClick(e, i)}
      >
        <div class="flex items-center justify-between px-2">
          <div class="line-clamp-1">
            {[unit.name, storageUnitTypesById.get(unit.typeId)?.name]
              .filter((a) => a)
              .join(" - ")}
          </div>
          <div class="text-green-400">
            {#if selection[i]}
              <svg class="w-5 h-5 text-green-400">
                <svg
                  id="check-circle"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clip-rule="evenodd"
                  />
                </svg>
              </svg>
            {/if}
          </div>
        </div>
      </li>
    {/each}
  </ul>
  <div
    class="border-t bg-white/50 backdrop-blur pt-2 rounded-b-lg absolute right-0 left-0 bottom-0"
  >
    {#if storageUnits.length === 0}
      <div class="text-center">No units available</div>
    {:else}
      <div class="text-center">
        {selected} selected
      </div>
    {/if}
    <div class="p-2">
      <button
        {disabled}
        class="{buttonClasses} transition-all btn w-full {disabled
          ? 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
          : ''}"
        on:click={handleCreateRowClick}
      >
        Add Row
      </button>
    </div>
  </div>
</div>
