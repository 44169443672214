import { Controller } from "@hotwired/stimulus"

export function loadScript(url, type = "text/javascript") {
  if (!url) return;

  const existingScript = document.querySelector("script[src*='" + url + "']");
  const head = document.querySelector("head");
  if (!existingScript && head) {
    const script = document.createElement("script");
    script.setAttribute("src", url);
    script.setAttribute("type", type);
    script.setAttribute("data-turbo-temporary", "true");
    head.appendChild(script);
  }
}

async function initTilled() {
  return new Promise((resolve) => {
    if (window.Tilled) {
      resolve();
    } else {
      loadScript("https://js.tilled.com/v2");
      let i = setInterval(() => {
        if (window.Tilled) {
          clearInterval(i);
          resolve();
        }
      }, 100);
    }
  });
}

const createIcon = (classes) => {
  const logo = document.createElementNS("http://www.w3.org/2000/svg", "svg")
  logo.classList.add(...classes)
  logo.innerHTML = `
    <svg id="exclamation-triangle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
      <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd"/>
    </svg>`;
  return logo
}

const createSVG = (url) => {
  const logo = document.createElementNS("http://www.w3.org/2000/svg", "svg")
  const use = document.createElementNS("http://www.w3.org/2000/svg", "use")
  use.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", url + "#flat-rounded-visa")
  logo.append(use)
  logo.classList.add("opacity-0", "absolute", "bottom-0", "transistion-all", "right-2", "h-10", "w-10")
  return logo
}

const createInput = (labelText, errorText) => {
  const container = document.createElement("div")
  container.classList.add("flex-1", "relative", "my-2")
  const label = document.createElement("label")
  label.innerText = labelText
  label.classList.add("block", "font-medium", "font-display", "sm:text-sm")
  const input = document.createElement("div")
  input.classList.add("h-10", "relative", "border", "border-gray-300", "px-3", "rounded-md", "bg-gray-50")

  const small = document.createElement("div")
  small.classList.add("text-red-500", "hidden", "font-display", "mt-1", "text-sm")
  small.appendChild(createIcon(["h-5", "w-5", "min-w-[20px]", "mt-[2px]"]))
  const span = document.createElement("span")
  span.classList.add("mt-[1px]")
  span.innerText = errorText
  small.appendChild(span)

  container.appendChild(label)
  container.appendChild(input)
  container.appendChild(small)
  return { container, input }
}

export default class extends Controller {
  static targets = ["token", "submit", "name", "zip", "address", "city", "accountType", "state"]
  static values = {
    publicApiKey: String,
    merchantAccountId: String,
    sandbox: Boolean,
    formType: String,
    icon: String,
    submitForm: { type: Boolean, default: true },
  };

  cardValid = false
  expiryValid = false
  cvcValid = false

  accountNumberValid = false
  routingNumberValid = false

  clickAdd = () => {
    this.submitTarget.click();
  }

  setupACHForm = async () => {
    this.tilled = new Tilled(
      this.publicApiKeyValue,
      this.merchantAccountIdValue,
      { sandbox: this.sandboxValue }
    )

    const container = document.createElement("div")
    container.dataset.turboTemporary = true
    container.classList.add("flex", "flex-col")

    const { container: accountNumberContainer, input: accountNumber } = createInput("Account Number*", "Your account number is invalid.")
    const { container: routingNumberContainer, input: routingNumber } = createInput("Routing Number*", "Your routing number is invalid.")
    container.append(accountNumberContainer)
    container.append(routingNumberContainer)
    this.element.prepend(container)


    const styles = {
      base: {
        fontFamily: 'PT Sans, Helvetica Neue, Arial, sans-serif',
      }
    }
    this.form = await this.tilled.form({ payment_method_type: "ach_debit" })
    const bankAccountNumberField = this.form.createField("bankAccountNumber", { selector: accountNumber, styles })
    bankAccountNumberField.on('blur', () => {
      this.updateFieldState(bankAccountNumberField, accountNumber)
    });
    bankAccountNumberField.on('change', (evt) => {
      this.accountNumberValid = evt.valid
      this.updateValidState();
    });

    const bankRoutingNumberField = this.form.createField("bankRoutingNumber", { selector: routingNumber, styles })
    bankRoutingNumberField.on('blur', () => {
      this.updateFieldState(bankRoutingNumberField, routingNumber)
    });
    bankRoutingNumberField.on('change', (evt) => {
      this.routingNumberValid = evt.valid
      this.updateValidState();
    });

    await this.form.build()

    if (this.submitFormValue) {
      this.element.closest("form").addEventListener("submit", (e) => {
        this.onSubmit(e, this.tilled)
      });
    }
  }

  setupCreditCardForm = async () => {
    this.tilled = new Tilled(
      this.publicApiKeyValue,
      this.merchantAccountIdValue,
      { sandbox: this.sandboxValue }
    )

    const container = document.createElement("div")
    container.dataset.turboTemporary = true
    container.classList.add("flex", "flex-col")

    const { container: cardNumberContainer, input: cardNumber } = createInput("Card Number*", "Your card number is invalid.")
    const { container: cardExpiryContainer, input: cardExpiry } = createInput("Expiration*", "Your card's expiration year is invalid.")
    const { container: cardCVCContainer, input: cardCVC } = createInput("CVC*", "Your card's security code is invalid.")

    const logo = createSVG(this.iconValue)
    cardNumberContainer.querySelector("div").appendChild(logo)
    container.appendChild(cardNumberContainer)

    const rowContainer = document.createElement("div")
    rowContainer.classList.add("flex", "flex-col", "md:flex-row", "md:gap-4")
    rowContainer.appendChild(cardExpiryContainer)
    rowContainer.appendChild(cardCVCContainer)
    container.appendChild(rowContainer)
    this.element.prepend(container)

    const styles = {
      base: {
        fontFamily: 'Inter, Helvetica Neue, Arial, sans-serif',
      }
    }

    this.form = await this.tilled.form({ payment_method_type: "card" })
    const cardNumberField = this.form.createField("cardNumber", { selector: cardNumber, placeholder: "1234 1234 1234 1234", styles })
    cardNumberField.on('blur', () => {
      this.updateFieldState(cardNumberField, cardNumber)
    });
    cardNumberField.on('change', (evt) => {
      this.cardValid = evt.valid
      const cardBrand = evt.brand;
      switch (cardBrand) {
        case 'amex':
        case 'visa':
        case 'mastercard':
        case 'discover':
        case 'diners':
        case 'maestro':
          logo.classList.remove("opacity-0")
          logo.classList.add("opacity-100", "transition-opacity", "ease-in", "duration-300")
          logo.querySelector("use").setAttribute("xlink:href", this.iconValue + "#flat-rounded-" + cardBrand)
          break;
        default:
          logo.classList.remove("opacity-100", "transition-opacity", "ease-in", "duration-700")
          logo.classList.add("opacity-0")
      }
      this.updateValidState();
    })

    const cardExpiryField = this.form.createField("cardExpiry", { selector: cardExpiry, placeholder: "MM / YY", styles })
    cardExpiryField.on('change', (evt) => {
      this.expiryValid = evt.valid
      this.updateValidState();
    });
    cardExpiryField.on('blur', () => {
      this.updateFieldState(cardExpiryField, cardExpiry)
    });

    const cardCvvField = this.form.createField("cardCvv", { selector: cardCVC, placeholder: "CVC", styles })
    cardCvvField.on('change', (evt) => {
      this.cvcValid = evt.valid
      this.updateValidState();
    });
    cardCvvField.on('blur', () => {
      this.updateFieldState(cardCvvField, cardCVC)
    });

    await this.form.build()

    if (this.submitFormValue) {
      this.element.closest("form").addEventListener("submit", (e) => {
        this.onSubmit(e, this.tilled)
      });
    }
  }

  createBankAccountPM = (tilled) => {
    return tilled.createPaymentMethod({
      type: "ach_debit",
      billing_details: {
        name: this.nameTarget.value,
        address: {
          street: this.addressTarget.value,
          city: this.cityTarget.value,
          state: this.stateTarget.value,
          zip: this.zipTarget.value,
          country: "US",
        },
      },
      ach_debit: {
        account_type: this.accountTypeTarget.value,
        account_holder_name: this.nameTarget.value,
      },
    })
  }

  createCreditCardPM = (tilled) => {
    return tilled.createPaymentMethod({
      type: "card",
      billing_details: {
        name: this.nameTarget.value,
        address: {
          zip: this.zipTarget.value,
          country: "US",
        },
      },
    })
  }

  onManualSubmit = (e) => {
    this.onSubmit(e, this.tilled)
  }

  onSubmit = async (e, tilled) => {
    if (this.tokenTarget.value.length) {
      return
    }
    e.preventDefault();

    this.submitTarget.disabled = true
    const originalText = this.submitTarget.innerText
    this.submitTarget.innerText = "Processing..."

    try {
      const lookupMethod = this.formTypeValue === "ach" ? this.createBankAccountPM : this.createCreditCardPM
      const paymentMethod = await lookupMethod(tilled)
      if (paymentMethod.id) {
        this.tokenTarget.value = paymentMethod.id

        const event = new CustomEvent("tokenized-payment-method:created", { detail: paymentMethod });
        document.dispatchEvent(event);

        if (this.submitFormValue) {
          this.element.closest("form").requestSubmit();
        }
        return
      }
    } catch (e) {
      let error = "Form is not valid.";
      if (e.message && e.message.includes("cardNumber")) {
        error = "Your card number is invalid"
      }

      alert(error)
      console.log(e)
    }

    this.submitTarget.disabled = false
    this.submitTarget.innerText = originalText
  }

  updateFieldState = (tilledField, fieldContainer) => {
    if (!tilledField._valid) {
      fieldContainer.classList.add("border-red-500")
      fieldContainer.nextSibling.classList.remove("hidden")
      fieldContainer.nextSibling.classList.add("flex")
    } else {
      fieldContainer.classList.remove("border-red-500")
      fieldContainer.nextSibling.classList.add("hidden")
      fieldContainer.nextSibling.classList.remove("flex")
    }
  }

  updateValidState = () => {
    let disabled = !(this.cardValid && this.expiryValid && this.cvcValid)
    if (this.formTypeValue === "ach") {
      disabled = !(this.accountNumberValid && this.routingNumberValid)
    }
    this.element.closest("form").disabled = disabled
    this.submitTarget.disabled = disabled
  }

  disconnect = async () => {
    await this.form.teardown()
  }

  connect = () => {
    initTilled().then(() => {
      if (this.formTypeValue === "ach") {
        this.setupACHForm()
      } else {
        this.setupCreditCardForm()
      }
    })
  }
}
