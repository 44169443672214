<script>
  import { createEventDispatcher } from "svelte"
  import StorageUnitRowPanelStorageUnitList from "./StorageUnitRowPanelStorageUnitList.svelte"

  const dispatch = createEventDispatcher()

  export let storageUnitRow

  function handleDeleteClick() {
    dispatch("deletestorageunitrow", { storageUnitRowId: storageUnitRow.id })
  }

  function handleSave() {
    let x = document.getElementById("storageUnitRowPositionXInput").value
    let y = document.getElementById("storageUnitRowPositionYInput").value
    x =
      x.replaceAll(/[^\.]/g, "").length > 1
        ? [x.split(".")[0], x.split(".")[1]].join(".")
        : x

    y =
      y.replaceAll(/[^\.]/g, "").length > 1
        ? [y.split(".")[0], y.split(".")[1]].join(".")
        : y

    document.getElementById("storageUnitRowPositionXInput").value = x
    document.getElementById("storageUnitRowPositionYInput").value = y

    dispatch("savestorageunitrow", {
      storageUnitRowId: storageUnitRow.id,
      position: { x, y },
      rotation: document.getElementById(
        "storage_unit_type_site_map_name_rotation"
      ).value,
    })
  }

  function rotate90() {
    const val = storageUnitRow.rotation
    let newSnap = 0
    if (val < 90) {
      newSnap = 90
    } else if (val < 180) {
      newSnap = 180
    } else if (val < 270) {
      newSnap = 270
    } else {
      newSnap = 0
    }

    updateRotation(newSnap)
  }

  function updateRotation(rotation) {
    storageUnitRow.rotation = rotation
    dispatch("savestorageunitrow", {
      storageUnitRowId: storageUnitRow.id,
      position: { x: storageUnitRow.position.x, y: storageUnitRow.position.y },
      rotation,
    })
  }

  let submitButton = document.querySelector("[type=submit]")
  let buttonClasses = ""
  if (submitButton) {
    buttonClasses = [...submitButton.classList, "w-full"]
      .filter((c) => c !== "sm:w-auto")
      .join(" ")
  }

  let secondaryButton = document.querySelector("#back_link")
  let secondaryButtonClasses = ""
  if (submitButton) {
    secondaryButtonClasses = [...secondaryButton.classList, "w-full"]
      .filter((c) => c !== "sm:w-auto")
      .join(" ")
  }

  function updatePosition(e) {
    let position = {
      x: storageUnitRow.position.x,
      y: storageUnitRow.position.y,
    }
    if (e.currentTarget.id === "storageUnitRowPositionXInput") {
      position.x = Number(e.currentTarget.value)
    } else if (e.currentTarget.id === "storageUnitRowPositionYInput") {
      position.y = Number(e.currentTarget.value)
    }

    dispatch("savestorageunitrow", {
      storageUnitRowId: storageUnitRow.id,
      position,
      rotation: storageUnitRow.rotation,
    })
  }

  function arrowPress(e) {
    const offset = e.shiftKey ? 10.0 : 1.0
    let n = Number(e.currentTarget.value)
    n = Number.isNaN(n) ? 0 : n
    if (e.key === "ArrowUp") {
      e.preventDefault()
      e.currentTarget.value = (n + offset).toFixed(2)
      updatePosition(e)
    }
    if (e.key === "ArrowDown") {
      e.preventDefault()
      e.currentTarget.value = (n - offset).toFixed(2)
      updatePosition(e)
    }
  }
</script>

<div class="border bg-white rounded-xl shadow font-display">
  <form on:submit|preventDefault={handleSave}>
    <div class="p-2 px-4">
      <div class="py-2">
        <label
          class="font-display text-sm font-medium text-gray-700"
          for="storageUnitRowPositionXInput">X</label
        >
        <input
          type="number"
          required
          inputmode="decimal"
          on:keydown={arrowPress}
          step="0.01"
          id="storageUnitRowPositionXInput"
          class="w-full border-gray-300 rounded-md font-display px-3 py-2 placeholder-gray-400 sm:text-sm"
          value={storageUnitRow.position.x.toFixed(2)}
        />
      </div>
      <div class="py-2">
        <label
          class="font-display text-sm font-medium text-gray-700"
          for="storageUnitRowPositionYInput">Y</label
        >
        <input
          type="number"
          step="0.01"
          required
          on:keydown={arrowPress}
          id="storageUnitRowPositionYInput"
          inputmode="decimal"
          class="w-full border-gray-300 rounded-md font-display px-3 py-2 placeholder-gray-400 sm:text-sm"
          value={storageUnitRow.position.y.toFixed(2)}
        />
      </div>

      <div class="my-2">
        <label
          class="font-display text-sm font-medium text-gray-700"
          for="storage_unit_type_site_map_name_rotation">Rotationº</label
        >
        <div class="flex items-center gap-2">
          <input
            min="0"
            max="360"
            data-range-rotate-target="range"
            data-controller="range"
            data-action="range#updateProgress"
            class="w-full rounded-md font-display placeholder-gray-400 sm:text-smtext-black focus:border-black focus:outline-none focus:ring-black border-gray-300 bg-gray-50 text-gray-900 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
            type="range"
            aria-hidden="true"
            on:input={(e) => {
              updateRotation(e.target.value)
            }}
            value={storageUnitRow.rotation}
            name="storage_unit_type[site_map_name_rotation]"
            id="storage_unit_type_site_map_name_rotation"
          />
          <button
            type="button"
            on:click={rotate90}
            class="bg-white hover:bg-gray-100 rounded-xl border p-2 text-slate-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m9 9 6-6m0 0 6 6m-6-6v12a6 6 0 0 1-12 0v-3"
              />
            </svg>
          </button>
        </div>
        <div class="font-display text-sm text-gray-500 mt-1">
          <span>{storageUnitRow.rotation}</span>º
        </div>
      </div>

      <button type="submit" class={buttonClasses}>Save</button>

      <div class="my-4">
        <button class={secondaryButtonClasses} on:click={handleDeleteClick}>
          Delete Row
        </button>
      </div>
    </div>
  </form>

  <StorageUnitRowPanelStorageUnitList
    storageUnits={storageUnitRow.storageUnits}
    on:reorderstorageunits={(e) =>
      dispatch("reorderstorageunits", {
        storageUnitRowId: storageUnitRow.id,
        storageUnitIds: e.detail.storageUnitIds,
      })}
  />
</div>
