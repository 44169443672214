import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["startDate", "nextBillDate"]

  validate() {
    const startDate = new Date(this.startDateTarget.value + " ")
    const nextBillDate = new Date(this.nextBillDateTarget.value + " ")

    if (startDate > nextBillDate && !this.nextBillDateTarget.disabled) {
      this.nextBillDateTarget.value = this.startDateTarget.value
    }
  }
}
