import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "paymentMethod"]

  toggle(e) {
    this.element.querySelectorAll("fieldset").forEach((fieldset) => {
      fieldset.disabled = false;
    });

    const fieldset = e.currentTarget.closest("fieldset")
    if (fieldset) {
      fieldset.disabled = !fieldset.disabled
    }
  }

  copyAddress(e) {
    const name = document.querySelector("#rental_full_name");

    const address = document.querySelector("#rental_address");
    const city = document.querySelector("#rental_city");
    const state = document.querySelector("#rental_state");
    const zip = document.querySelector("#rental_postal_code");

    const ccName = document.querySelector("#cc_full_name");
    const ccZip = document.querySelector("#cc_zip");

    const bankName = document.querySelector("#full_name");
    const bankZip = document.querySelector("#zip");
    const bankAddress = document.querySelector("#street_address");
    const bankCity = document.querySelector("#city");
    const bankState = document.querySelector("#state");

    if (e.currentTarget.checked) {
      ccName.value = name.value;
      bankName.value = name.value;
      ccZip.value = zip.value;
      bankZip.value = zip.value;

      bankAddress.value = address.value;
      bankCity.value = city.value;
      bankState.selectedIndex = state.selectedIndex;
    } else {
      ccName.value = "";
      bankName.value = "";
      ccZip.value = "";
      bankZip.value = "";

      bankAddress.value = "";
      bankCity.value = "";
    }
  }
}
