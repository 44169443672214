import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pacIdSelect"]

  connect() {
    this.pacIdChange()
  }

  pacIdChange() {
    this.element.querySelectorAll("[data-pac-id]").forEach(el => {
      if (el.dataset.pacId == this.pacIdSelectTarget.value) {
        el.classList.remove("hidden")
      } else {
        el.classList.add("hidden")
      }
    })
  }
}
