import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  copyToClipboard(textToCopy) {
    window.navigator.clipboard.writeText(textToCopy)
  }

  copy(event) {
    event.preventDefault()
    const text = event.currentTarget.dataset.copy
    this.copyToClipboard(text)
    const button = event.currentTarget
    const newButton = button.cloneNode(true)

    let newText = newButton.querySelector("div") || newButton
    newText.innerText = "Copied!"

    newButton.classList.add("text-green-500")

    button.classList.add("hidden")
    button.insertAdjacentElement('afterend', newButton)
    setTimeout(() => {
      newButton.remove()
      button.classList.remove("hidden")
    }, 3000);
  }
}