import { Controller } from "@hotwired/stimulus";

function loadScript(url, type = "text/javascript") {
	if (!url) return;

	const existingScript = document.querySelector("script[src*='" + url + "']");
	const head = document.querySelector("head");
	if (!existingScript && head) {
		const script = document.createElement("script");
		script.setAttribute("src", url);
		script.setAttribute("type", type);
		script.setAttribute("data-turbo-temporary", "true");
		head.appendChild(script);
	}
}

async function initMapKit() {
	return new Promise((resolve) => {
		if (window.mapkit) {
			resolve();
		} else {
			loadScript("https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js");
			let i = setInterval(() => {
				if (window.mapkit) {
					clearInterval(i);
					resolve();
				}
			}, 100);
		}
	});
}

// interface AnnotationType {
// 	latitude: number;
// 	longitude: number;
// 	color?: string;
// 	title?: string;
// 	subtitle?: string;
// 	selected?: boolean;
// 	glyphText?: string;
// }

export default class extends Controller {
	static values = {
		jwt: String,
		address: String,
		name: String,
		annotations: Array,
	};

	// declare jwtValue: string;
	// declare annotationsValue: AnnotationType[];
	// map: mapkit.Map | undefined = undefined;
	map = undefined;

	lookupAddress = () => {
		this.map = new mapkit.Map(this.element);
		const geocoder = new mapkit.Geocoder({});
		geocoder.lookup(this.addressValue, (r, b) => {
			if (b && b.results && b.results.length > 0) {
				const location = b.results[0]
				const MarkerAnnotation = mapkit.MarkerAnnotation;
				const coordinate = new mapkit.Coordinate(location.coordinate.latitude, location.coordinate.longitude);
				const annotation = new MarkerAnnotation(coordinate, {
					color: "#000000",
					title: this.nameValue,
					subtitle: "",
					selected: true,
					glyphText: "📦",
				});
				const items = [annotation];
				this.map.showItems(items);
			}
		});
	}

	loadMap = () => {
		const jwt = this.jwtValue;
		const mapkit = window.mapkit;
		if (!mapkit) {
			return;
		}

		if (window.mapkitInitialized) {
			this.lookupAddress()
		} else {

			mapkit.init({
				authorizationCallback: (done) => {
					window.mapkitInitialized = true;
					done(jwt);
					this.lookupAddress()
				},
			})
		}
		// this.map = new mapkit.Map(this.element);
		// const items = this.annotationsValue.map((a) => {
		// 	const {
		// 		latitude,
		// 		longitude,
		// 		color = "#000000",
		// 		title = "",
		// 		subtitle = "",
		// 		selected = false,
		// 		glyphText = "",
		// 	} = a;

		// 	const MarkerAnnotation = mapkit.MarkerAnnotation;
		// 	const coordinate = new mapkit.Coordinate(latitude, longitude);
		// 	const annotation = new MarkerAnnotation(coordinate, {
		// 		color,
		// 		title,
		// 		subtitle,
		// 		selected,
		// 		glyphText,
		// 	});
		// 	return annotation;
		// });

		// this.map.showItems(items);
	};

	disconnect() {
		this.map.destroy();
		window.mapkit = undefined
	}

	connect() {
		initMapKit().then(this.loadMap);
	}
}
