import { Controller } from "@hotwired/stimulus";
import { post } from "api"
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["list", "item"]
  static values = {
    handle: String,
    url: String
  }

  connect() {
    new Sortable(this.listTarget, {
      ...(this.hasHandleValue ? { handle: this.handleValue } : {}),
      animation: 450,
      direction: "vertical",
      easing: "cubic-bezier(0, 1, 0, 1)",
      dragClass: "bg-gray-400",
      ghostClass: "opacity-0",
      onEnd: (detail) => {
        const event = new CustomEvent('onSortEnd', { detail });
        this.element.dispatchEvent(event);

        if (this.hasUrlValue) {
          const item_ids = this.itemTargets.map(el => el.dataset.sortableItemId)
          post(this.urlValue, { item_ids })
        }
      }
    });
  }
}

