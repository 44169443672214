import { Controller } from "@hotwired/stimulus"
import SiteMapEditor from "../components/site_map/SiteMapEditor.svelte"

export default class extends Controller {
  static values = {
    siteMapId: String,
  }

  connect() {
    this.siteMapEditor = new SiteMapEditor({
      target: this.element,
      props: {
        siteMapId: this.siteMapIdValue
      }
    })
  }

  disconnect() {
    this.siteMapEditor.$destroy()
  }
}
