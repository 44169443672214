<script>
  import { createEventDispatcher, getContext, setContext } from 'svelte'

  const { xScale, yScale } = getContext('LayerCake')
  setContext('ContainerPointerHandler', { createDomainPointerEvent })

  const dispatch = createEventDispatcher()

  let element

  function createDomainPointerEvent(pointerEvent, entityType, entityId) {
    const rect = element.getBoundingClientRect()
    const elementX = pointerEvent.clientX - rect.left
    const elementY = pointerEvent.clientY - rect.top
    const type = `domain${pointerEvent.type}`
    const detail = {
      pointerEvent,
      entityType,
      entityId,
      elementX,
      elementY,
      xScale: $xScale,
      yScale: $yScale,
      x: $xScale.invert(elementX),
      y: $yScale.invert(elementY),
    }
    return {type, detail}
  }

  function handlePointerEvent(e) {
    const {type, detail} = createDomainPointerEvent(e)
    dispatch(type, detail)
  }
</script>

<div
  bind:this={element}
  class="w-full h-full"
  on:pointerdown={handlePointerEvent}
  on:pointerup={handlePointerEvent}
  on:pointermove={handlePointerEvent}
>
  <slot></slot>
</div>
