<script>
  import { getContext } from 'svelte'

  const { data, xGet, yGet, custom } = getContext('LayerCake')
  $: drawing = $custom.drawing
  $: polygonPoints = $data.map(p => `${$xGet(p)},${$yGet(p)}`).join(' ')
</script>

{#if drawing}
  <polyline
    class="fill-gray-400 stroke-2 stroke-white opacity-80"
    points={polygonPoints}
  />
{:else}
  <polygon
    class="fill-gray-400 stroke-2 stroke-white opacity-80"
    points={polygonPoints}
  />
{/if}

{#each $data as p}
  <circle
    class="fill-white"
    cx={$xGet(p)}
    cy={$yGet(p)}
    r={4}
  />
{/each}
