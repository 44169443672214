import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  sentMessage(text) {
    const div = this.element.querySelector(".justify-end").cloneNode(true)
    div.querySelector("span").innerText = text
    this.element.querySelector("div").appendChild(div)
    div.scrollIntoView()
  }

  fromMessage(text) {
    const div = this.element.querySelector(".justify-start").cloneNode(true)
    div.querySelector("span").innerText = text
    this.element.querySelector("div").appendChild(div)
    div.scrollIntoView()
  }

  newMessage(e) {
    console.log(e.keyCode)
    if (e.key === "Enter") {
      console.log("ENTERRR")
      const val = e.currentTarget.value.trim()
      e.currentTarget.value = ""
      if (val.length > 0) {
        this.sentMessage(val)

        setTimeout(() => {
          this.fromMessage("I'm a bot, I don't understand anything")
        }, 3000)
      }
    }
  }

  connect() {
    const div = this.element.querySelector(".justify-end")
    div.scrollIntoView()
  }
}
