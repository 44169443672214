export const isDebug = () => !window.location.hostname.includes("ccstorage.com")

export const numberToCurrency = (number: number | string): string => {
  return Number(number).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })
}

export function setCookie(name: string, value: any, days: number) {
  var expires = ""
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

export function getCookie(name: string) {
  var nameEQ = name + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function debounce(func: any, timeout = 300) {
  let timer: string | number | NodeJS.Timeout | undefined
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args)
    }, timeout)
  }
}

export function pluralize(amount: number, plural: string, singular: string) {
  return `${amount} ${amount === 1 ? singular : plural}`
}
