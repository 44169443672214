<script>
  export let zoomIn
  export let zoomOut
  export let zoomReset
</script>

<div
  class="absolute bottom-4 right-4 bg-black/50 backdrop-blur shadow-lg rounded-lg text-white overflow-clip"
>
  <div class="rounded-xl divide-y">
    <button
      aria-label="Reset zoom"
      title="Reset zoom"
      on:click={(e) => {
        e.preventDefault()
        zoomReset()
      }}
      class="hover:bg-black/20 cursor-pointer w-10 h-10 flex items-center justify-center text-2xl"
    >
      <svg class="w-5 h-5 text-white-500">
        <svg
          id="arrow-path"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
            clip-rule="evenodd"
          />
        </svg>
      </svg>
    </button>
    <button
      on:click={(e) => {
        e.preventDefault()
        zoomIn()
      }}
      class="hover:bg-black/20 cursor-pointer p-4 w-10 h-10 flex items-center justify-center text-2xl"
    >
      +
    </button>
    <button
      on:click={(e) => {
        e.preventDefault()
        zoomOut()
      }}
      class="hover:bg-black/20 cursor-pointer p-4 w-10 h-10 flex items-center justify-center text-2xl"
    >
      -
    </button>
  </div>
</div>
