<script>
  import { getContext } from 'svelte'

  const { xScale, yScale } = getContext('LayerCake')

  export let mapWidth
  export let mapHeight
  export let mapBackgroundImageUrl

  let fill = "#DDD"
  if (mapBackgroundImageUrl) {
    fill = "transparent"
  }
</script>


<rect
  x={$xScale(0)}
  y={$yScale(0)}
  width={$xScale(mapWidth) - $xScale(0)}
  height={$yScale(mapHeight) - $yScale(0)}
  fill={fill}
  stroke="#333"
  stroke-width="2"
/>
