import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle = (e) => {
    e.preventDefault()
    const use = e.currentTarget.querySelector("use")
    const href = use.getAttribute("xlink:href")
    const icon = href.includes("#eye-slash") ? href.replace("#eye-slash", "#eye") : href.replace("#eye", "#eye-slash")
    use.setAttribute("xlink:href", icon)
    const field = e.currentTarget.previousElementSibling
    field.type = field.type === "password" ? "text" : "password"
  }
}
