import { Controller } from "@hotwired/stimulus"
import SiteMapViewer from "@/components/site_map/SiteMapViewer.svelte"

export default class extends Controller {
  static values = {
    siteMapId: String,
  }

  connect() {
    this.siteMapViewer = new SiteMapViewer({
      target: this.element,
      props: {
        siteMapId: this.siteMapIdValue
      }
    })
  }

  disconnect() {
    this.siteMapViewer.$destroy()
  }
}
