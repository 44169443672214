<script>
  import Moveable from "svelte-moveable"
  import { getContext } from "svelte"

  export let lease
  export let edit
  export let editing = lease.editing || false
  export let update
  export let hide
  export let cullText = false

  const maxWidth = "auto"
  const maxHeight = "auto"
  const minWidth = "auto"
  const minHeight = "auto"
  const draggable = true
  const throttleDrag = 1
  const edgeDraggable = false
  const startDragRotate = 0
  const throttleDragRotate = 0
  const resizable = true
  const keepRatio = false
  const throttleResize = 1
  const renderDirections = ["nw", "n", "ne", "w", "e", "sw", "s", "se"]
  const rotatable = true
  const throttleRotate = 0
  const rotationPosition = "top"
  let targetRef = null
  let moveableRef = null

  // API Call
  function savePosition(width, height, transform) {
    update(lease.id, { width, height, transform })
  }

  let baseClases = "border-2 cursor-pointer"
  let classes =
    "border-2 bg-emerald-100 border-emerald-400 hover:bg-emerald-200 cursor-pointer"
  let pastDueClasses = "bg-red-100 border-red-400 hover:bg-red-200"
  if (lease.occupancyStatus === "past_due") {
    classes = pastDueClasses
  }

  let unitModal
  let setSelectedLease = getContext("setSelectedLease")

  function onlyNumbers(str) {
    return str.replace(/\D/g, "")
  }

  function onClick() {
    if (!edit) {
      return
    }

    editing = !editing
  }

  let filteredClasses = "opacity-100"
  $: {
    if (hide) {
      filteredClasses = "opacity-20"
    } else if (filteredClasses.length > 0) {
      filteredClasses = "opacity-100"
    }
  }

  $: viewClasses = edit
    ? "bg-gray-200 hover:bg-gray-300"
    : [baseClases, classes, filteredClasses].join(" ")
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div
  tabindex="-1"
  role="contentinfo"
  on:keypress={(e) => {
    if (!edit) {
      setSelectedLease(
        lease.serial_num,
        e.currentTarget.getBoundingClientRect()
      )
    }
  }}
  on:mouseenter={(e) => {
    if (!edit) {
      setSelectedLease(
        lease.serial_num,
        e.currentTarget.getBoundingClientRect()
      )
    }
  }}
  on:click={onClick}
  class="transition-opacity duration-500 target absolute flex items-center justify-center text-center {viewClasses} {editing
    ? 'cursor-move'
    : 'cursor-pointer'}"
  bind:this={targetRef}
  style={`transform: ${lease.transform}; width: ${lease.width}px; height: ${lease.height}px; max-width: ${maxWidth};max-height: ${maxHeight};min-width: ${minWidth};min-height: ${minHeight};`}
>
  <span
    class="transition-opacity duration-500 {cullText
      ? 'opacity-0'
      : 'opacity-100'}"
  >
    Lease #{lease.serial_num}
  </span>
</div>

<div bind:this={unitModal} class="bg-white p-4 min-h-20 fixed z-50 hidden">
  Unit for lease
</div>

{#if edit && editing}
  <Moveable
    bind:this={moveableRef}
    target={targetRef}
    {draggable}
    {throttleDrag}
    {edgeDraggable}
    {startDragRotate}
    {throttleDragRotate}
    {resizable}
    {keepRatio}
    {throttleResize}
    {renderDirections}
    {rotatable}
    {throttleRotate}
    {rotationPosition}
    on:drag={({ detail: e }) => {
      e.target.style.transform = e.transform
      savePosition(
        onlyNumbers(e.target.style.width),
        onlyNumbers(e.target.style.height),
        e.transform
      )
    }}
    on:resize={({ detail: e }) => {
      e.target.style.width = `${e.width}px`
      e.target.style.height = `${e.height}px`
      e.target.style.transform = e.drag.transform
      savePosition(e.width, e.height, e.drag.transform)
    }}
    on:rotate={({ detail: e }) => {
      e.target.style.transform = e.drag.transform
      savePosition(
        onlyNumbers(e.target.style.width),
        onlyNumbers(e.target.style.height),
        e.drag.transform
      )
    }}
  />
{/if}
