import { Controller } from "@hotwired/stimulus"
import * as FilePond from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
FilePond.registerPlugin(FilePondPluginImagePreview);
FilePond.registerPlugin(FilePondPluginFileValidateSize);

const photoIcon = (classes = "") => {
  return `
    <svg class="${classes}" id="photo-o" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"/>
    </svg>
  `
}

const arrowUpIcon = (classes = "") => {
  return `
    <svg id="arrow-up-tray" class="${classes}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
      <path fill-rule="evenodd" d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z" clip-rule="evenodd"/>
    </svg>
  `
}

const label = (image) => `
<div class="filepond--label-action flex flex-col items-center justify-center text-gray-400">
  ${image.length > 0 ? `<img src=${image} class="h-24 rounded-lg shadow object-cover mx-auto" />` : photoIcon("w-24 h-24")}
  <div class="flex items-center justify-center py-2 gap-4">
    ${arrowUpIcon("w-6 h-6")}
    <div class="font-display no-underline upload-label">${image.length > 0 ? "Replace" : "Upload"} File</div>
  </div>
</div>
`

export default class extends Controller {
  static values = {
    maxFileSize: { default: "25MB", type: String },
    replaceImage: { default: "", type: String },
    allowImagePreview: { default: false, type: Boolean },
    driversLicense: { default: false, type: Boolean },
    proofOfSelfInsurance: { default: false, type: Boolean }
  };

  connect() {
    const form = this.element.closest("form")
    FilePond.create(this.element, {
      maxFileSize: this.maxFileSizeValue,
      allowImagePreview: this.allowImagePreviewValue,
      labelIdle: label(this.replaceImageValue),
      credits: false,
      server: {
        url: "/filepond",
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        process: {
          ondata: (formData) => {
            document.querySelector(".upload-label").innerText = "Upload more files"
            for (let pair of formData.entries()) {
              if (typeof pair[1].name == 'string') {
                const newData = new FormData()
                newData.append("file", pair[1])
                if (this.driversLicenseValue) {
                  const contentType = document.createElement("input")
                  contentType.type = "hidden"
                  contentType.name = "content_type"
                  contentType.value = pair[1].type
                  form.appendChild(contentType)
                  newData.append("drivers_license", true)
                }
                if (this.proofOfSelfInsuranceValue) {
                  newData.append("proof_of_self_insurance", true)
                }

                return newData
              }
            }

            return formData;
          }
        }
      }
    })
  }
}
