import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  static values = { pricePerFeet: { type: Number, default: 0 } }

  update(e) {
    this.inputTarget.value = (e.currentTarget.value * this.pricePerFeetValue).toFixed(2)
  }

  connect() {
    const currentTarget = this.element.querySelector("#create_lease_feet_leased")
    if (currentTarget) {
      this.update({ currentTarget })
    }
  }
}
