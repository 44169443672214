<script>
  import { createEventDispatcher, getContext } from 'svelte'
  import StorageUnit from './StorageUnit.svelte'

  const dispatch = createEventDispatcher()
  const { xScale, yScale } = getContext('LayerCake')
  const { createDomainPointerEvent } = getContext('ContainerPointerHandler')

  export let storageUnitTypesById
  export let storageUnitRow
  export let selected

  let storageUnitsAndPositions
  let width
  let height
  function init(storageUnitRow) {
    let x = 0
    let y = 0
    width = 0
    height = 0
    storageUnitsAndPositions = storageUnitRow.storageUnits.map(storageUnit => {
      const obj = {x, y, storageUnit}
      const storageUnitType = storageUnitTypesById.get(storageUnit.typeId)
      x += storageUnitType.shape.width
      width += storageUnitType.shape.width
      height = Math.max(height, storageUnitType.shape.height)
      return obj
    })
  }
  $: init(storageUnitRow)

  let gTransform
  $: gTransform = `translate(${$xScale(storageUnitRow.position.x)}, ${$yScale(storageUnitRow.position.y)}) rotate(${storageUnitRow.rotation})`

  function handlePointerEvent(e) {
    const {type, detail} = createDomainPointerEvent(e, "storageUnitRow", storageUnitRow.id)
    dispatch(type, detail)
  }

</script>

<g
  transform={gTransform}
  on:pointerdown|stopPropagation={handlePointerEvent}
  on:pointermove|stopPropagation={handlePointerEvent}
>
  {#each storageUnitsAndPositions as sup}
    <StorageUnit
      x={sup.x}
      y={sup.y}
      storageUnit={sup.storageUnit}
      {storageUnitTypesById}
      storageUnitRowSelected={selected}
    />
  {/each}
</g>
