import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    tokenizationKey: String,
  }

  loadScript() {
    return new Promise((res, rej) => {
      if (!document.querySelector("#collectjs-styles")) {
        const styles = document.createElement('style');
        styles.id = "collectjs-styles";
        styles.innerText = `.CollectJSInlineIframe, .CollectJSInlineIframe.CollectJSInvalid { height: 38px !important; }`
        document.head.appendChild(styles);
      }

      if (!window.CollectJS) {
        const script = document.createElement('script');
        script.src = "https://retrieveronline.transactiongateway.com/token/Collect.js";
        script.dataset.tokenizationKey = this.tokenizationKeyValue;
        script.dataset.variant = "inline";
        script.dataset.customCss = `{
          "border": "solid 1px #d1d5db",
          "border-radius": "0.375rem",
          "color": "#343434",
          "display": "block",
          "height": "38px",
          "font-size": "16px",
          "width": "100%",
          "padding": "8px 12px",
          "line-height": "1.5",
          "background-color": "rgb(249, 250, 251)",
          "box-sizing": "border-box"
        }`

        script.onload = res;
        script.onerror = rej;
        document.head.appendChild(script);
      }
    })
  }

  connect() {
    this.loadScript().then(() => {
      this.initCollect();
    })
  }

  initCollect() {
    CollectJS.configure({
      paymentType: "cc",
      invalidCss: {
        "border": "solid 1px red",
      },
      validationCallback: function (id, valid, message) {
        const errorDiv = document.querySelector(`#${id}-errors`)

        if (valid) {
          errorDiv.classList.add("hidden")
        } else {
          errorDiv.classList.remove("hidden")
          errorDiv.querySelector("span").innerText = message
        }
      },
      callback: function (response) {
        const event = new CustomEvent("tokenized-payment-method:created", { detail: response });
        document.dispatchEvent(event);
      }
    })
  }
}