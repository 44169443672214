import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"]

  onPaste(e) {
    const clipboard = e.clipboardData.getData("text").replace("-", "")
    this.element.querySelectorAll("input").forEach((input, index) => {
      input.value = clipboard.charAt(index)
      input.blur()
    })

    this.element.closest("form").requestSubmit()
  }

  focusNext(e) {
    if (e.currentTarget.value.length < 1) return

    if (e.currentTarget.nextElementSibling.tagName === "DIV") {
      e.currentTarget.nextElementSibling.nextElementSibling.focus()
    } else {
      e.currentTarget.nextElementSibling.focus()
    }
  }

  submit(e) {
    if (e.currentTarget.value.length < 1) return

    let submit = true
    this.element.querySelectorAll("input").forEach((input) => {
      if (input.value.length < 1) {
        input.focus()
        submit = false
        return
      }
    })

    if (submit) {
      e.currentTarget.blur()
      this.element.closest("form").requestSubmit()
    }
  }
}