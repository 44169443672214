import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["taxRateContainer", "template"]

  removeTaxRate = (e) => {
    const node = e.currentTarget
    const container = node.closest(".rate-row")

    let existing = false
    container.querySelectorAll("[type=hidden]").forEach(n => {
      if (n.name.includes("[id]")) {
        existing = true
      }
    })

    if (!existing) {
      container.remove()
      return
    }

    const checkbox = container.querySelector("input[type='checkbox']")
    checkbox.checked = true
    container.querySelector(".to-remove").classList.remove("hidden")
  }

  addTaxRate = () => {
    const row = document.importNode(this.templateTarget.content, true);
    row.querySelectorAll("[name]").forEach(n => {
      const nextNumber = document.querySelectorAll(".rate-row").length
      n.name = n.name.replace(/tax_policy\[tax_policy_rates_attributes\]\[\d\]/, `tax_policy[tax_policy_rates_attributes][${nextNumber}]`)
    })

    this.taxRateContainerTarget.appendChild(row)
  }
}
