<script>
  import { onMount, setContext } from "svelte"
  import MapView from "./MapView.svelte"
  import StorageUnitRowPanel from "./StorageUnitRowPanel.svelte"
  import UnassignedStorageUnitsPanel from "./UnassignedStorageUnitsPanel.svelte"

  export let siteMapId

  let debugStore = { enabled: false }
  function updateDebugStore(options) {
    debugStore = { ...debugStore, ...options }
  }
  setContext("updateDebugStore", updateDebugStore)

  onMount(load)

  let status = "loading"
  let loadError
  let storageUnitTypes
  let storageUnitTypesById
  let storageUnitRows
  let storageUnitRowsById
  let unassignedStorageUnits
  let mapWidth
  let mapHeight
  let mapBackgroundImageUrl

  let viewXCenter = 50
  let viewYCenter = 50
  let viewWidth = 100

  let selectedStorageUnitRowId

  const minViewWidth = 50

  async function load() {
    try {
      const response = await fetch(`/company_admin/site_maps/${siteMapId}`, {
        headers: { Accept: "application/json" },
      })
      const siteMap = await response.json()
      storageUnitTypes = siteMap.storageUnitTypes
      for (const storageUnitType of storageUnitTypes) {
        storageUnitType.shape.width = Math.max(
          ...storageUnitType.shape.map((p) => p[0])
        )
        storageUnitType.shape.height = Math.max(
          ...storageUnitType.shape.map((p) => p[1])
        )
      }
      storageUnitTypesById = new Map(
        siteMap.storageUnitTypes.map((sut) => [sut.id, sut])
      )

      storageUnitRows = siteMap.storageUnitRows
      storageUnitRowsById = new Map(
        siteMap.storageUnitRows.map((sur) => [sur.id, sur])
      )

      unassignedStorageUnits = siteMap.unassignedStorageUnits
      mapWidth = siteMap.width
      mapHeight = siteMap.height
      mapBackgroundImageUrl = siteMap.backgroundImageUrl

      status = "ready"
    } catch (error) {
      status = "loadFailed"
      loadError = error.message
    }
  }

  let dragState
  let spacePressed = false
  let panState

  function handleDomainPointerDown(e) {
    if (e.detail.entityType !== "storageUnitRow") {
      panState = {
        startElementX: e.detail.elementX,
        startElementY: e.detail.elementY,
        startViewXCenter: viewXCenter,
        startViewYCenter: viewYCenter,
        startXScale: e.detail.xScale.copy(),
        startYScale: e.detail.yScale.copy(),
      }
    } else {
      if (e.detail.entityType == "storageUnitRow") {
        selectedStorageUnitRowId = e.detail.entityId
        const storageUnitRow = storageUnitRowsById.get(selectedStorageUnitRowId)

        dragState = {
          startX: e.detail.x,
          startY: e.detail.y,
          currentX: e.detail.x,
          currentY: e.detail.y,
          storageUnitRowId: e.detail.entityId,
          originalPosition: storageUnitRow.position,
        }
      } else {
        selectedStorageUnitRowId = null
      }
    }
  }

  async function handleDomainPointerUp(e) {
    panState = null

    if (dragState) {
      if (
        dragState.startX != dragState.currentX &&
        dragState.startY != dragState.currentY
      ) {
        try {
          const storageUnitRow = storageUnitRowsById.get(
            dragState.storageUnitRowId
          )

          const response = await fetch(
            `/company_admin/site_maps/${siteMapId}/site_map_storage_unit_rows/${dragState.storageUnitRowId}`,
            {
              method: "PATCH",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRF-Token": document
                  .querySelector("meta[name='csrf-token']")
                  .getAttribute("content"),
              },
              body: JSON.stringify({
                position: storageUnitRow.position,
              }),
            }
          )
          if (!response.ok) {
            throw new Error("Failed to save storage unit row position")
          }
        } catch (error) {
          status = "saveFailed"
          loadError = error.message
        }
      }

      dragState = null
    } else {
      selectedStorageUnitRowId = null
    }
  }

  function handleDomainPointerMove(e) {
    if (panState) {
      const offsetX =
        panState.startXScale.invert(e.detail.elementX) -
        panState.startXScale.invert(panState.startElementX)
      const offsetY =
        panState.startYScale.invert(e.detail.elementY) -
        panState.startYScale.invert(panState.startElementY)

      viewXCenter = panState.startViewXCenter - offsetX
      viewYCenter = panState.startViewYCenter - offsetY
    } else if (dragState) {
      dragState.currentX = e.detail.x
      dragState.currentY = e.detail.y

      const storageUnitRow = storageUnitRowsById.get(dragState.storageUnitRowId)
      storageUnitRow.position = {
        x:
          dragState.originalPosition.x +
          (dragState.currentX - dragState.startX),
        y:
          dragState.originalPosition.y +
          (dragState.currentY - dragState.startY),
      }
      storageUnitRows = storageUnitRows
      storageUnitRowsById = storageUnitRowsById
    }
  }

  function zoomOut() {
    viewWidth = viewWidth * 1.25
    constrainView()
  }

  function zoomIn() {
    viewWidth = viewWidth * 0.8
    constrainView()
  }

  function zoomReset() {
    viewWidth = 100
    constrainView()
  }

  function handleKeyDown(event) {
    if (event.key === " ") {
      event.preventDefault()
      spacePressed = true
    } else if (event.key === "=" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      zoomIn()
    } else if (event.key === "-" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      zoomOut()
    } else if (event.key === "0" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      zoomReset()
    } else if (event.key === "d") {
      debugStore.enabled = !debugStore.enabled
    }
  }

  function handleKeyUp(event) {
    if (event.key === " ") {
      event.preventDefault()
      spacePressed = false
    }
  }

  function constrainView() {
    if (viewWidth < minViewWidth) {
      viewWidth = minViewWidth
    }

    const maxViewWidth = mapWidth * 3
    if (viewWidth > maxViewWidth) {
      viewWidth = maxViewWidth
    }
    if (viewXCenter - viewWidth / 2 < 0) {
      viewXCenter = viewWidth / 2
    }
    if (viewXCenter + viewWidth / 2 > mapWidth) {
      viewXCenter = mapWidth - viewWidth / 2
    }
    // if (viewYCenter < 0) { viewYCenter = 0 }
    // if (viewYCenter > mapHeight) { viewYCenter = mapHeight }
  }

  $: updateDebugStore({
    viewXCenter: viewXCenter.toFixed(4),
    viewYCenter: viewYCenter.toFixed(4),
    viewWidth: viewWidth.toFixed(4),
  })

  async function handleCreateStorageUnitRow(e) {
    try {
      const response = await fetch(
        `/company_admin/site_maps/${siteMapId}/site_map_storage_unit_rows`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector("meta[name='csrf-token']")
              .getAttribute("content"),
          },
          body: JSON.stringify({
            storage_unit_ids: e.detail.storageUnits.map((su) => su.id),
          }),
        }
      )
      if (response.ok) {
        load()
      } else {
        throw new Error("Failed to create storage unit row")
      }
    } catch (error) {
      status = "loadFailed"
      loadError = error.message
    }
  }

  async function handleSaveStorageUnitRow(e) {
    try {
      const { storageUnitRowId, position, rotation } = e.detail

      const response = await fetch(
        `/company_admin/site_maps/${siteMapId}/site_map_storage_unit_rows/${storageUnitRowId}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector("meta[name='csrf-token']")
              .getAttribute("content"),
          },
          body: JSON.stringify({ position, rotation }),
        }
      )
      if (response.ok) {
        load()
      } else {
        throw new Error("Failed to save storage unit row")
      }
    } catch (error) {
      status = "loadFailed"
      loadError = error.message
    }
  }

  async function handleReorderStorageUnits(e) {
    try {
      const { storageUnitRowId, storageUnitIds } = e.detail

      const response = await fetch(
        `/company_admin/site_maps/${siteMapId}/site_map_storage_unit_rows/${storageUnitRowId}/reorder_storage_units`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector("meta[name='csrf-token']")
              .getAttribute("content"),
          },
          body: JSON.stringify({ storage_unit_ids: storageUnitIds }),
        }
      )
      if (response.ok) {
        load()
      } else {
        throw new Error("Failed to reorder storage units")
      }
    } catch (error) {
      status = "loadFailed"
      loadError = error.message
    }
  }

  async function handleDeleteStorageUnitRow(e) {
    try {
      const response = await fetch(
        `/company_admin/site_maps/${siteMapId}/site_map_storage_unit_rows/${e.detail.storageUnitRowId}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": document
              .querySelector("meta[name='csrf-token']")
              .getAttribute("content"),
          },
        }
      )
      if (response.ok) {
        selectedStorageUnitRowId = null
        load()
      } else {
        throw new Error("Failed to delete storage unit row")
      }
    } catch (error) {
      status = "loadFailed"
      loadError = error.message
    }
  }

  let submitButton = document.querySelector("[type=submit]")
  let buttonClasses = ""
  if (submitButton) {
    buttonClasses = [...submitButton.classList].join(" ")
  }
  const backURL =
    (document.querySelector("#back_link") &&
      document.querySelector("#back_link").href) ||
    "#"

  function handleWheel(event) {
    event.preventDefault()
    const multiplier = event.ctrlKey ? 2 : 1
    const maxViewWidth = mapWidth * 5
    let scale = viewWidth + event.deltaY * multiplier
    scale = Math.min(maxViewWidth, Math.max(minViewWidth, scale))
    viewWidth = scale
  }
</script>

<svelte:window on:keydown={handleKeyDown} on:keyup={handleKeyUp} />

{#if status == "loading"}
  <p>Site map loading...</p>
{:else if status == "ready"}
  <div class="bg-gray-100 h-[100vh] w-full flex drop-shadow z-10">
    <div class="w-[250px] overflow-y-scroll p-4">
      <a class={buttonClasses + " mb-4 z-20"} href={backURL}> Back </a>

      {#if selectedStorageUnitRowId}
        <div>
          <StorageUnitRowPanel
            storageUnitRow={storageUnitRowsById.get(selectedStorageUnitRowId)}
            on:savestorageunitrow={handleSaveStorageUnitRow}
            on:deletestorageunitrow={handleDeleteStorageUnitRow}
            on:reorderstorageunits={handleReorderStorageUnits}
          />
        </div>
      {:else}
        <div class="absolute top-0 bottom-0 left-0 w-[250px]">
          <UnassignedStorageUnitsPanel
            {storageUnitTypesById}
            storageUnits={unassignedStorageUnits}
            on:createstorageunitrow={handleCreateStorageUnitRow}
          />
        </div>
      {/if}
    </div>

    {#if debugStore.enabled}
      <div
        class="rounded-lg p-3 bg-black/40 text-white absolute z-10 top-4 right-4 max-w-[300px]"
      >
        {#each Object.entries(debugStore) as [key, value]}
          <div>{key}: {value}</div>
        {/each}
      </div>
    {/if}

    <div class="flex-1 overflow-hidden" on:wheel={handleWheel}>
      <MapView
        {storageUnitTypesById}
        {storageUnitRows}
        {selectedStorageUnitRowId}
        {mapWidth}
        {mapHeight}
        {mapBackgroundImageUrl}
        {viewXCenter}
        {viewYCenter}
        {viewWidth}
        {spacePressed}
        on:domainpointerdown={handleDomainPointerDown}
        on:domainpointerup={handleDomainPointerUp}
        on:domainpointermove={handleDomainPointerMove}
      />

      <div
        class="fixed top-4 right-4 bg-black/50 backdrop-blur shadow-lg rounded-lg text-white overflow-clip"
      >
        <div class="rounded-xl divide-y">
          <button
            on:click={(e) => {
              document
                .querySelector("#map-editor")
                .closest("dialog")
                .querySelector(".showModal")
                .click()
            }}
            class="hover:bg-black/20 cursor-pointer p-2 w-auto h-10 flex items-center gap-2 justify-center text-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        class="fixed bottom-4 right-4 bg-black/50 backdrop-blur shadow-lg rounded-lg text-white overflow-clip"
      >
        <div class="rounded-xl divide-y">
          <button
            aria-label="Reset zoom"
            title="Reset zoom"
            on:click={(e) => {
              e.preventDefault()
              zoomReset()
            }}
            class="hover:bg-black/20 cursor-pointer w-10 h-10 flex items-center justify-center text-2xl"
          >
            <svg class="w-5 h-5 text-white-500">
              <svg
                id="arrow-path"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                  clip-rule="evenodd"
                />
              </svg>
            </svg>
          </button>
          <button
            on:click={(e) => {
              e.preventDefault()
              zoomIn()
            }}
            class="hover:bg-black/20 cursor-pointer p-4 w-10 h-10 flex items-center justify-center text-2xl"
          >
            +
          </button>
          <button
            on:click={(e) => {
              e.preventDefault()
              zoomOut()
            }}
            class="hover:bg-black/20 cursor-pointer p-4 w-10 h-10 flex items-center justify-center text-2xl"
          >
            -
          </button>
        </div>
      </div>
    </div>
  </div>
{:else}
  <p>Error: {loadError}</p>
{/if}
