import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  clear(event) {
    if (this.element === event.target || this.element.contains(event.target)) return
    this.element.children[0].innerHTML = ""
  }

  previousFocus(e) {
    let focus = false
    const links = this.element.querySelectorAll("a")
    if (links.length === 0) return
    e.preventDefault()

    links.forEach((a, idx) => {
      if (!focus && a === document.activeElement) {
        focus = true
        if (idx === 0) {
          links[links.length - 1]?.focus()
        } else {
          links[idx - 1]?.focus()
        }
      }
    })

    if (!focus) {
      links[links.length - 1]?.focus()
    }
  }

  nextFocus(e) {
    let focus = false
    const links = this.element.querySelectorAll("a")
    if (links.length === 0) return
    e.preventDefault()

    links.forEach((a, idx) => {
      if (!focus && a === document.activeElement) {
        focus = true
        if (idx === links.length - 1) {
          links[0]?.focus()
        } else {
          links[idx + 1]?.focus()
        }
      }
    })

    if (!focus) {
      links[0]?.focus()
    }
  }
}
