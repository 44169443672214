<script>
  import CanvasDimensionsForm from "./CanvasDimensionsForm.svelte"
  import Canvas from "./Canvas.svelte"
  import UnitNameForm from "./UnitNameForm.svelte"

  export let storageUnitType

  let canvasWidth
  let canvasHeight
  let screenWidth
  let screenHeight
  let polygon
  let namePosition
  let nameRotation

  function handlePropsUpdated(storageUnitType) {
    canvasWidth = 0
    canvasHeight = 0
    for (const p of storageUnitType.polygon) {
      if (p[0] > canvasWidth) {
        canvasWidth = p[0]
      }
      if (p[1] > canvasHeight) {
        canvasHeight = p[1]
      }
    }
    canvasWidth = Math.ceil(canvasWidth)
    canvasHeight = Math.ceil(canvasHeight)

    polygon = [...storageUnitType.polygon]
    namePosition = storageUnitType.namePosition
    nameRotation = storageUnitType.nameRotation
  }
  $: handlePropsUpdated(storageUnitType)

  function computeScreenDimensions(canvasWidth, canvasHeight) {
    const maxSideLength = 500
    if (canvasHeight > canvasWidth) {
      screenHeight = maxSideLength
      screenWidth = maxSideLength * (canvasWidth / canvasHeight)
    } else {
      screenWidth = maxSideLength
      screenHeight = maxSideLength * (canvasHeight / canvasWidth)
    }
  }
  $: computeScreenDimensions(canvasWidth, canvasHeight)

  function handleDimensionFormSubmit(e) {
    canvasWidth = e.detail.canvasWidth
    canvasHeight = e.detail.canvasHeight
    polygon = [
      [0, 0],
      [0, canvasHeight],
      [canvasWidth, canvasHeight],
      [canvasWidth, 0],
    ]
    namePosition = { x: canvasWidth / 2, y: canvasHeight / 2 }
    handleNamePositionChanged({ detail: { namePosition } })
    document.querySelector(
      "[name='storage_unit_type[site_map_name_position][x]']"
    ).value = namePosition.x
    document.querySelector(
      "[name='storage_unit_type[site_map_name_position][x]']"
    ).value = namePosition.x
    document.querySelector(
      "[name='storage_unit_type[site_map_name_position][y]']"
    ).value = namePosition.y
    document.querySelector("[name='storage_unit_type[site_map_shape]']").value =
      JSON.stringify(polygon)
  }

  let mode = "editName"
  function handleEditShapeButtonClick() {
    mode = "editShape"
  }

  function handleCancelEditShapeButtonClick() {
    mode = "view"
  }

  function handlePolygonChanged(e) {
    polygon = [...e.detail.polygon]
    mode = "view"
  }

  namePosition = storageUnitType.namePosition
  nameRotation = storageUnitType.nameRotation
  let inProgressNamePosition = namePosition
  let inProgressNameRotation = nameRotation

  function handleEditNameButtonClick() {
    mode = "editName"
    inProgressNamePosition = namePosition
    inProgressNameRotation = nameRotation
  }

  function handleAcceptEditNamePositionButtonClick() {
    mode = "view"
    namePosition = inProgressNamePosition
    nameRotation = inProgressNameRotation
  }

  function handleCancelEditNamePositionButtonClick() {
    mode = "view"
  }

  function handleNamePositionChanged(e) {
    inProgressNamePosition = e.detail.namePosition

    document.querySelector(
      "[name='storage_unit_type[site_map_name_position][x]']"
    ).value = e.detail.namePosition.x
    document.querySelector(
      "[name='storage_unit_type[site_map_name_position][y]']"
    ).value = e.detail.namePosition.y
  }

  function handleNameProposedChange(e) {
    inProgressNamePosition = { x: e.detail.x, y: e.detail.y }
    inProgressNameRotation = e.detail.rotation
  }

  let snapToGrid = true

  async function handleSaveButtonClick() {
    const response = await fetch(
      `/company_admin/storage_unit_type_shapes/${storageUnitType.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        credentials: "same-origin",
        body: JSON.stringify({
          storage_unit_type: {
            site_map_shape: polygon,
            site_map_name_position: namePosition,
            site_map_name_rotation: nameRotation,
          },
        }),
      }
    )

    if (response.ok) {
      window.location = `/company_admin/storage_unit_types/${storageUnitType.id}`
    } else {
      alert("Failed to save.")
    }
  }
</script>

<CanvasDimensionsForm
  {canvasWidth}
  {canvasHeight}
  on:changedimensions={handleDimensionFormSubmit}
/>

<Canvas
  containerClass="mt-4"
  {screenWidth}
  {screenHeight}
  {canvasWidth}
  {canvasHeight}
  {mode}
  {snapToGrid}
  {polygon}
  namePosition={mode === "editName" ? inProgressNamePosition : namePosition}
  nameRotation={mode === "editName" ? inProgressNameRotation : nameRotation}
  on:polygonchanged={handlePolygonChanged}
  on:namepositionchanged={handleNamePositionChanged}
/>

<div class="mt-4">
  {#if mode === "editShape"}
    <label>
      Snap to grid
      <input type="checkbox" bind:checked={snapToGrid} />
    </label>

    <button
      type="button"
      class="btn"
      on:click={handleCancelEditShapeButtonClick}
    >
      Cancel
    </button>
  {:else if mode === "editName"}
    <UnitNameForm
      x={inProgressNamePosition.x}
      y={inProgressNamePosition.y}
      rotation={inProgressNameRotation}
      on:propose={handleNameProposedChange}
      on:accept={handleAcceptEditNamePositionButtonClick}
      on:cancel={handleCancelEditNamePositionButtonClick}
    />
  {:else}
    <button type="button" class="btn" on:click={handleEditShapeButtonClick}>
      Edit Shape
    </button>

    <button type="button" class="btn" on:click={handleEditNameButtonClick}>
      Edit Name
    </button>
  {/if}
</div>

{#if mode === "view"}
  <div class="mt-4">
    <button type="button" class="btn" on:click={handleSaveButtonClick}>
      Save
    </button>
  </div>
{/if}
