import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "card",
    "ach",
    "cardContainer",
    "achContainer",
    "cardButton",
    "achButton",
    "name",
    "token",
    "select",
    "address",
    "city",
    "state",
    "zip",
    "cardError"
  ]
  static values = {
    url: String,
    src: String,
    publicKey: String,
  }

  connect() {
    this.init().then(this.loadFrame)
    this.selectedType = "card"
  }

  showCard = (e) => {
    this.selectedType = "card"
    this.cardContainerTarget.classList.remove("hidden")
    this.cardButtonTarget.classList.remove("bg-white", "cursor-pointer", "hover:bg-gray-100")
    this.cardButtonTarget.classList.add("bg-black", "text-white", "cursor-default")

    this.achContainerTarget.classList.add("hidden")
    this.achButtonTarget.classList.add("bg-white", "cursor-pointer")
    this.achButtonTarget.classList.remove("text-white", "cursor-default")

    this.addressTarget.parentElement.parentElement.classList.add("hidden")
    this.addressTarget.required = false

    this.cityTarget.parentElement.parentElement.classList.add("hidden")
    this.cityTarget.required = false

    this.stateTarget.parentElement.parentElement.classList.add("hidden")
    this.stateTarget.required = false
  }

  showAch = (e) => {
    this.selectedType = "ach"
    this.achContainerTarget.classList.remove("hidden")
    this.achButtonTarget.classList.remove("bg-white", "cursor-pointer", "hover:bg-gray-100")
    this.achButtonTarget.classList.add("bg-black", "text-white", "cursor-default")

    this.cardContainerTarget.classList.add("hidden")
    this.cardButtonTarget.classList.add("bg-white", "cursor-pointer")
    this.cardButtonTarget.classList.remove("text-white", "cursor-default")

    this.addressTarget.parentElement.parentElement.classList.remove("hidden")
    this.addressTarget.required = true

    this.cityTarget.parentElement.parentElement.classList.remove("hidden")
    this.cityTarget.required = true

    this.stateTarget.parentElement.parentElement.classList.remove("hidden")
    this.stateTarget.required = true
  }

  init() {
    return new Promise((resolve, reject) => {
      if (window.Tokenizer) { return resolve() }

      const script = document.createElement('script')
      script.src = this.srcValue
      script.onerror = reject
      script.onload = () => {
        resolve()
      }
      document.head.appendChild(script)
    })
  }

  tokenSubmission = (resp) => {
    setTimeout(() => {
      this.element.querySelectorAll("button").forEach(b => b.disabled = false)
    }, 4000)

    switch (resp.status) {
      case 'success':
        this.tokenTarget.value = resp.token
        this.cardTarget.innertText = "Card added"
        this.achTarget.innertText = "Bank account added"

        if (this.element.dataset.submitOnToken) {
          this.tokenTarget.closest("form").requestSubmit()
        }
        if (this.element.dataset.selfSignUp) {
          this.dispatch("success", { prefix: "tokenized-status" })
        }
        break;
      case 'error':
        console.log("Card Error", { resp })
        break;
      case 'validation':
        if (resp.invalid.includes("cc")) {
          this.cardErrorTarget.classList.remove("hidden")
        } else {
          this.cardErrorTarget.classList.add("hidden")
        }
        break;
      default:
        console.log("Error", { resp })
    }
  }

  loadFrame = () => {
    this.styles = this.computeStyles()
    this.ach = this.newToken("ach", this.achTarget)
    this.card = this.newToken("card", this.cardTarget)
  }

  computeStyles = () => {
    const style = getComputedStyle(this.nameTarget)
    const inputStyles = {
      color: style.getPropertyValue("color"),
      'border-radius': style.getPropertyValue("border-radius"),
      'font-size': style.getPropertyValue("font-size"),
      'font-weight': style.getPropertyValue("font-weight"),
      'font-family': style.getPropertyValue("font-family"),
      'background-color': style.getPropertyValue("background-color"),
      border: style.getPropertyValue("border"),
      padding: style.getPropertyValue("padding"),
      margin: style.getPropertyValue("margin"),
      "line-height": style.getPropertyValue("line-height"),
      "margin-bottom": "8px",
      height: style.getPropertyValue("height"),
    }

    const selectStyle = getComputedStyle(this.selectTarget)
    const selectStyles = {
      ...inputStyles,
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      "appearance": "none",
      padding: "8px",
      "padding-right": "16px",
      "background-image": selectStyle.getPropertyValue("background-image"),

      "background-position-x": "calc(100% - 8px)",
      "background-position-y": "50%",
      "background-repeat": selectStyle.getPropertyValue("background-repeat"),
      "background-size": selectStyle.getPropertyValue("background-size"),
    }
    const styles = {
      ".user .group .fieldset, .card .fieldset": {
        'padding-left': '16px',
        'padding-right': '16px',
      },
      ".fieldset.last_name": {
        'padding-bottom': '0px !important',
        'margin-bottom': '0px !important',
      },
      ".card,.ach": {
        'padding-left': '16px',
        'padding-right': '16px',
      },
      ".ach": {
        'padding-top': '6px',
      },
      ".first_name label, .last_name label": {
        "display": "none"
      },
      ".card": {
        "padding-top": "8px !important",
        "column-gap": "8px"
      },
      ".card .fieldset": {
        'padding-left': '16px',
        'padding-right': '16px',
      },
      ".fieldset.first_name": {
        "padding-top": "8px !important"
      },
      ".fieldset.cc": {
      },
      ".fieldset.exp": {
        "padding-left": "0px !important",
        "padding-right": "0px !important"
      },
      ".fieldset.cvv": {
        "padding-left": "0px !important",
        "padding-right": "8px !important"
      },
      "input:focus,select:focus": {
        outline: 'rgba(0, 0, 0, 0) solid 2px',
        "outline-offset": '2px',
        "box-shadow": 'rgb(255, 255, 255) 0px 0px 0px 0px, rgb(0, 0, 0) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px',
        "border-color": "#000000 !important",
      },
      ".user": {
        "margin-bottom": "0px",
        "padding-bottom": "0px",
        'padding-left': '16px !important',
        'padding-right': '16px !important',
      },
      ".user .group.inline>div.fieldset.last_name": {
        "padding-left": "8px",
        "padding-top": "8px"
      },
      ".billing": {
        'padding-left': '16px !important',
        'padding-right': '16px !important',
        'padding-bottom': '0px !important',
      },
      ".billing label": {
        display: "none"
      },
      ".billing > div:last-child": {
        display: "none"
      },
      ".cc-icon": {
        left: "10px !important",
        top: "-4px !important",
      },
      '.card .cc input, input': inputStyles,
      ".fieldset.cc input.cc-input": {
        'padding-left': '35px !important',
      },
      ".card .exp": {
        display: "contents"
      },
      ".card .exp input": {
        width: "67.5% !important"
      },
      ".card .cvv": {
        display: "contents"
      },
      ".card .cvv input": {
        width: "30% !important"
      },
      'select': selectStyles,
    }

    return styles
  }

  newToken = (type, container) => {
    return new Tokenizer({
      url: this.urlValue,
      apikey: this.publicKeyValue,
      container,
      submission: this.tokenSubmission,
      settings: {
        styles: this.styles,
        payment: {
          types: [type],
          ach: {
            showSecCode: false,
            sec_code: "ppd",
            secCodeList: ["ppd"]
          },
          card: {
            requireCVV: true,
          },
        }
      }
    })
  }

  submit = (e) => {
    if (this.tokenTarget.value.length > 5) {
      this.element.closest("form").requestSubmit()
      return
    }

    e.currentTarget.disabled = true
    if (this.selectedType === "ach") {
      this.ach.submit()
    }
    if (this.selectedType === "card") {
      this.card.submit()
    }
  }

  submitAch = (e) => {
    this.submit(e)
  }
  submitCard = (e) => {
    this.submit(e)
  }

  ensureToken = (e) => {
    if (this.tokenTarget.value.length < 5) {
      e.preventDefault()
      window.confirmMethod("Account invalid", e.currentTarget, `Please enter your ${this.selectedType === "ach" ? "bank account" : "card"} information`)
    }
  }
}
